@mixin news-page-text {
    display: flex;
    flex-wrap: wrap;
    max-width: none;
    width: auto;
    margin-left: -#{$gutter};
    margin-right: -#{$gutter};
    margin-top: $baseline * 2;
    & > * {
        order: 2;
        flex-grow: 1;
        margin-left: $gutter;
        margin-right: $gutter;
        margin-top: $baseline;
    }
    .date-posted {
        flex-basis: 100%;
    }
    .image {
        flex-basis: 30%;
    }
    .text {
        flex-basis: #{360/$em}rem;
        order: 1;
        max-width: $content-width;
    }
}