@import 'parent:';

.item-group.page {
    max-width: none;
}

.breadcrumbs {
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: $baseline * 2;
    }
    li {
        line-height: 1.2;
    }
    li:not(:last-child) {
        margin-right: .5em;
    }
    li:not(:first-child) {
        &::before {
            content: '/';
            margin-right: .5em;
        }
    }
}

.item-group.secondary-navigation {
    margin-top: $baseline * 4;
}
.item-group.categories-select {
    .input {
        margin-top: 0;
        margin-bottom: $baseline;
    }
    @include breakpoint(medium up) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .item-wrapper {
            flex-grow: 1;
            margin-right: 1rem;
        }    
    }
}
.categories-select + .article-list {
    margin-top: 0;
}

.item-group.article-list .show .button {
    font-size: (12/$em) * 1rem;
}

.rte {
    img {
        width: 100%;
    }
}
