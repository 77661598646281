@import '@foundation/util/unit';
@import '@foundation/util/value';
$baseline: .75rem !default;
$alert-color: #d50603 !default;
$warning-color: #f4783e !default;
$success-color: #a6b639 !default;
$required-color: #d50603 !default;

//ripped from foundation
@function text-inputs($types: ()) {
  $return: ();

  $all-types:
    text
    password
    date
    datetime
    datetime-local
    month
    week
    email
    number
    search
    tel
    phone
    time
    url
    color;

  @if not has-value($types) {
    $types: $all-types;
  }

  @each $type in $types {
    $return: append($return, unquote('[type=\'#{$type}\']'), comma);
  }

  @return $return;
}

$button-colors: (
    'base': (
        background: transparent,
        text: $text-color-regular,
        border: initial,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
    'fill': (
        background: $anchor-color,
        text: $text-color-white,
        border: $anchor-color,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
) !default;

@mixin form-base(
    $border-color: $light-gray,
    $border-color-focus: $text-color-regular
) {
    input,
    textarea {
        border: 1px solid $border-color;
        outline: 0;
        &:focus {
            border: 1px solid $border-color-focus;
        }
    }
    .message {
        @include form-message;
        &.warning {
            color: $warning-color;
            &::after {
                @include sb-icon-exclamation($warning-color);
            }
        }
        &.success {
            &::after {
                @include sb-icon-check($success-color);
            }
        }
        &.error {
            color: $alert-color;
            &::after {
                @include sb-icon-close($alert-color);
            }
        }
    }
}

@mixin form-message {
    position: relative;
    padding-left: 1.4em;
    min-height: 1em;
    line-height: 1;
    &::after {
        @include sb-icon-exclamation($text-color-regular);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        background-size: 1em 1em;
        background-position: center;
        background-repeat: no-repeat;
    }
}

@mixin input-warning {
    @include form-message;
    color: $warning-color;
    &::after {
        @include sb-icon-exclamation($warning-color);
    }
}
@mixin input-success {
    @include form-message;
    &::after {
        @include sb-icon-check($success-color);
    }
}

@mixin input-error {
    @include form-message;
    color: $alert-color;
    &::after {
        @include sb-icon-close($alert-color);
    }
}

@mixin input-colors(
    $input-background: $white,
    $input-background-focus: $input-background,
    $input-background-disabled: $light-gray,
    $input-border: $light-gray,
    $input-border-focus: $text-color-regular,
    $input-border-alert: $alert-color,
    $checkbox: $gray,
    $text: $text-color-regular,
    $label-text: $text,
    $placeholder-text: $text-color-light
) {
    color: $text;
    ::placeholder {
        color: $placeholder-text;
    }
    :-ms-input-placeholder {
        color: $placeholder-text !important;
    }
    #{text-inputs()},
    select,
    textarea,
    fieldset {
        background-color: $input-background;
        border-color: $input-border;
        &:focus {
            background-color: $input-background-focus;
            border-color: $input-border-focus;
        }
        &:disabled,
        &[readonly] {
            background-color: $input-background-disabled;
        }
    }
    label,
    legend {
        color: $label-text;
    }
    fieldset {
        background-color: $input-background;
        border-color: $input-border;
        &:focus-within {
            border-color: $input-border-focus;
        }
    }
    select {
        @include sb-icon-arrow-down($text);
    }
    [type="checkbox"],
    [type="radio"] {
        & + label {
            color: $text;
        }
        & + label::before,
        & + label::after {
            color: $checkbox;
        }
        &:focus + label::before,
        &:focus + label::after {
            color: $input-border-focus;
        }
    }
    &.has-error-message input,
    &.has-error-message select,
    &.has-error-message textarea {
        border-color: $input-border-alert;
        &:focus {
            border-color: $input-border-focus;
        }
    }

    //placeholder labels
    input,
    textarea {
        &:not(:focus):not(.has-value) + label {
            color: $placeholder-text;
        }
    }
}
@mixin input-all(
    $base-style: input-label-top,
    $additional-styles: (
        '.label-placeholder': input-label-placeholder,
        '.button': button-base,
        '.switch': input-switch
    ),
    $props: ()
) {
    .input {
        @include apply-input-mixin(input-base, $props);
        @include apply-input-mixin($base-style, $props);
    }
    @each $selector, $mixin-name in $additional-styles {
        #{$selector} {
            @include apply-input-mixin($mixin-name, $props);
        }
    }
}
@mixin apply-input-mixin($mixin, $props) {
    $default-props: (
        vertical-margin: $baseline,
        input-padding: $baseline * .75,
        input-border: 1px solid,
        label-font-size: 1rem,
        label-line-height: 1.25rem,
        input-font-size: 1rem,
        input-line-height: 1.5rem,
        input-border-radius: .3rem
    );
    $base-props: map-merge($default-props, $props);
    @if $mixin == input-base {
        @include input-base(
            $vertical-margin: map-get($base-props, vertical-margin),
            $input-padding: map-get($base-props, input-padding),
            $input-border: map-get($base-props, input-border),
            $label-font-size: map-get($base-props, label-font-size),
            $label-line-height: map-get($base-props, label-line-height),
            $input-font-size: map-get($base-props, input-font-size),
            $input-line-height: map-get($base-props, input-line-height),
            $input-border-radius: map-get($base-props, input-border-radius)
        );
    } @elseif $mixin == input-label-top {
        $label-top-props: map-merge((
            label-margin: $baseline / 2,
        ), $props);
        @include input-label-top(
            $label-margin: map-get($label-top-props, label-margin)
        );
    } @elseif $mixin == input-label-absolute {
        $label-absolute-props: map-merge((
            label-font-size: 0.85rem,
            label-x-offset: 0.4rem, 
            label-padding: .2rem, 
            outer-background-color: $white,
            inner-background-color: transparent,
            input-border-width: get-border-value(map-get($base-props, input-border), width)
        ), $props);
        @include input-label-absolute(
            map-get($label-absolute-props, label-font-size),
            map-get($label-absolute-props, label-x-offset),
            map-get($label-absolute-props, label-padding),
            map-get($label-absolute-props, outer-background-color),
            map-get($label-absolute-props, inner-background-color),
            map-get($label-absolute-props, input-border-width)
        );
    } @elseif $mixin == input-label-placeholder {
        $label-placeholder-default-props: map-merge($default-props, (
            label-font-size: 0.85rem,
            label-x-offset: 0.4rem, 
            label-padding: .2rem, 
            outer-background-color: $white,
            inner-background-color: transparent,
            input-border-width: get-border-value(map-get($base-props, input-border), width),
            placeholder-color: $text-color-light
        ));
        $label-placeholder-props: map-merge($label-placeholder-default-props, $props);
        @include input-label-placeholder(
            map-get($label-placeholder-props, label-font-size),
            map-get($label-placeholder-props, label-x-offset),
            map-get($label-placeholder-props, label-padding),
            map-get($label-placeholder-props, outer-background-color),
            map-get($label-placeholder-props, inner-background-color),
            map-get($label-placeholder-props, input-border-width),
            map-get($label-placeholder-props, input-padding),
            map-get($label-placeholder-props, input-line-height),
            map-get($label-placeholder-props, input-font-size),
            map-get($label-placeholder-props, placeholder-color)
        );
    } @elseif $mixin == input-switch {
        $switch-props: map-merge((
            checked-color: $primary-color,
            unchecked-color: lighten(desaturate($primary-color, 70%), 40%),
            text-size: 1rem
        ), $props);
        @include input-switch (
            map-get($switch-props, checked-color),
            map-get($switch-props, unchecked-color),
            map-get($switch-props, text-size)
        );
    } @elseif $mixin == button-base {
        $button-props: map-merge((
            min-width: 6rem,
            input-border-width: get-border-value(map-get($base-props, input-border), width)
        ), $props);
        @include button-base (
            $padding: map-get($button-props, input-padding),
            $border-radius: map-get($button-props, input-border-radius),
            $border-width: map-get($button-props, input-border-width),
            $min-width: map-get($button-props, min-width),
            $font-size: map-get($button-props, input-font-size),
            $line-height: map-get($button-props, input-line-height)
        );
    }
}

@mixin input-base(
    $vertical-margin: $baseline,
    $input-padding: $baseline * .75,
    $input-border: 1px solid,
    $label-font-size: 1rem,
    $label-line-height: 1.25rem,
    $input-font-size: 1rem,
    $input-line-height: 1.5rem,
    $input-border-radius: .3rem
) {
    // @include input-reset;
    $line-height: $input-line-height;
    @if unit($input-line-height) == '' {
        $line-height: $input-font-size * $input-line-height;
    }
    $border-width: get-border-value($input-border, width);
    $height: calc(#{$line-height}
             + #{get-side($input-padding, top)}
             + #{get-side($input-padding, bottom)});
    margin-top: $vertical-margin;

    label,
    legend {
        display: block;
        font-size: $label-font-size;
        line-height: $label-line-height;
    }

    #{text-inputs()},
    select,
    textarea {
        display: block;
        width: 100%;
        padding-top: calc(#{get-side($input-padding, top)} - #{$border-width});
        padding-right: calc(#{get-side($input-padding, right)} - #{$border-width});
        padding-bottom: calc(#{get-side($input-padding, bottom)} - #{$border-width});
        padding-left: calc(#{get-side($input-padding, left)} - #{$border-width});
        background-position: 
            right calc(#{get-side($input-padding, right)} - #{$border-width})
            top calc(#{get-side($input-padding, top)} - #{$border-width});
        background-size: $line-height $line-height;
        background-repeat: no-repeat;
        border: $input-border;
        border-radius: $input-border-radius;
        font-size: $input-font-size;
        line-height: $input-line-height;
        -webkit-appearance: none;
        -moz-appearance: none;
        transition: background-color .2s ease,
                    box-shadow .2s ease,
                    border .2s ease;
        &:focus {
            outline: 0;
        }
    }

    &.required > label::after,
    &.required legend::after {
        content: "*";
        display:inline;
        vertical-align: middle;
        line-height: 0;
        margin-left: .4em;
        color: $required-color;
    }

    #{text-inputs()},
    select {
        height: $height;
    }
    textarea {
        min-height: $height;
        resize: vertical;
        &[rows] {
            height: auto;
        }
    }
    select {
        @include sb-icon-arrow-down($text-color-regular);
        width: auto;
        padding-right: 1.75rem;
        background-size: .75rem .75rem;
        background-position: right .5rem center;
        background-repeat: no-repeat;
    }
    select::-ms-expand {
        display: none;
    }
    legend {
        margin-bottom: -#{$label-line-height / 2};
    }
    fieldset {
        margin: 0;
        margin-top: -#{$label-line-height / 2};
        border: $input-border;
        border-radius: $input-border-radius;
        padding-top: calc(#{get-side($input-padding, top) * 1.5} - #{$border-width});
        padding-right: calc(#{get-side($input-padding, right)} - #{$border-width});
        padding-bottom: calc(#{get-side($input-padding, bottom)} - #{$border-width});
        padding-left: calc(#{get-side($input-padding, left)} - #{$border-width});
        transition: background-color .2s ease,
                    box-shadow .2s ease,
                    border .2s ease;
    }
    
    .input-error {
        @include input-error;
    }
    .radio-buttons,
    .checkbox-list {
        li {
            @include input-checkbox($input-font-size, $input-line-height);
        }
    }

    // XXX Workaround for chrome not firing autofill events.
    @keyframes onAutoFillStart {
        from {/**/}
        to {/**/}
    }

    @keyframes onAutoFillCancel {
        from {/**/}
        to {/**/}
    }

    input {
        &:-webkit-autofill {
            // Expose a hook for JavaScript when auto fill is shown.
            // JavaScript can capture 'animationstart' events
            animation-name: onAutoFillStart;
            transition: background-color 0.3s ease-in-out 0s;
        }

        &:not(:-webkit-autofill) {
            // Expose a hook for JS onAutoFillCancel
            // JavaScript can capture 'animationstart' events
            animation-name: onAutoFillCancel;
        }
    }
    // XXX End workaround.
}
@mixin input-checkbox(
    $label-font-size: 1rem, 
    $label-line-height: 1.25rem, 
    $checkbox-size: 1rem, 
    $checkbox-border-width: 2px,
    $checkbox-top-margin: calc((#{$label-line-height} - #{$checkbox-size}) / 2)
) {
    position: relative;
    [type="radio"],
    [type="checkbox"] {
        position: absolute;
        width: 0px;
        height: 0px;
        overflow: hidden;
        margin: 0;
        opacity: 0;
    }
    label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        font-size: $label-font-size;
        line-height: $label-line-height;
        &::before,
        &::after {
            content: '';
            display: block;
            width: $checkbox-size;
            height: $checkbox-size;
            margin-top: $checkbox-top-margin;
        }
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-color: currentColor;
            transform-origin: center;
            transform: scale(0);
            transition: color .2s, transform .15s;
        }
        &::after {
            order: -1;
            position: relative;
            flex-shrink: 0;
            margin-right: .5em;
            border: $checkbox-border-width solid;
            transition: color .2s;
        }
    }
    input:hover + label::before {
        transform: scale(.2);
    }
    input:checked + label::before {
        transform: scale(.4);
    }
    [type="radio"] {
        & + label::before {
        }
        & + label::after {
            border-radius: 50%;
        }
    }
    [type="checkbox"] {
        & + label::before {

            transition: color .2s, transform .15s .05s, border-radius .05s;
        }
        & + label::after {
            background-size: $checkbox-size $checkbox-size;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    [type="checkbox"]:checked {
        & + label::before {
            transform: scale(1);
            border-radius: 0;
            transition: color .2s, transform .15s, border-radius .05s .10s;
        }
        & + label::after {
            @include sb-icon-check-invert($white);
        }
    }
}
@mixin input-label-top(
    $label-margin: $baseline / 2,
) {
    display: flex;
    flex-direction: column;
    label {
        order: 1;
    }
    #{text-inputs()},
    textarea {
        order: 2;
        margin-top: $label-margin;
    }
    .input-error {
        order: 3;
    }
    .button {
        align-self: center;
    }
}
@mixin input-label-absolute(
    $label-font-size: 0.85rem,
    $label-x-offset: 0.4rem, 
    $label-padding: .2rem, 
    $outer-background-color: $white,
    $inner-background-color: transparent,
    $input-border-width: 1px
) {
    position: relative;
    display: flex;
    flex-direction: column;
    .button {
        align-self: center;
    }
    fieldset {
        padding-left: calc(#{$label-x-offset} + #{$label-padding}); 
        padding-right: calc(#{$label-x-offset} + #{$label-padding}); 
    }
    legend {
        font-size: $label-font-size;
        padding: 0 $label-padding;
        margin-left: -#{$label-padding};
        margin-right: -#{$label-padding};
    }
    & > label,
    .input-error {
        position: relative;
        padding: 0 $label-padding;
        max-width: calc(100% - #{$label-x-offset * 2});
        font-size: $label-font-size;
        line-height: 1;
        background-color: $inner-background-color;
        &::before {
            z-index: -1;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            background-color: $outer-background-color;
        }
    }
    // select {
    // }
    // & > input,
    // & > select,
    // & > textarea {
    //     order: 2;
    //     width: 100%;
    // }
    & > label {
        z-index: 4;
        // top: -0.5em;
        margin-top: -0.5em;
        margin-bottom: -0.5em;
        order: -1;
        align-self: flex-start;
        left: $label-x-offset;
        &::before {
            top: 0;
            //fudge 2 border width to make up for pixel rounding
            bottom: calc(.5em - #{$input-border-width * 2});
        }
    }
    .input-error {  
        z-index: 6;
        position: absolute;
        // order: 3;
        top: calc(100% - .5em);
        // margin-top: -0.5em;
        align-self: flex-end;
        right: $label-x-offset;
        padding-left: calc(#{$label-padding} + 1.4em);
        min-height: 1em;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::after {
            left: $label-padding;
        }
        &::before {
            top: calc(.5em - #{$input-border-width * 2});
            bottom: 0;
        }
    }
    & > input:focus ~ .input-error,
    &:hover .input-error {
        white-space: normal;
        text-overflow: initial;
    }
}
@mixin input-label-placeholder(
    $label-font-size: 0.85em,
    $label-x-offset: 0.4rem, 
    $label-padding: .2rem, 
    $outer-background-color: $white,
    $inner-background-color: transparent,
    $input-border-width: 1px,
    $input-padding: $baseline * .75,
    $input-line-height: 1.5rem,
    $input-font-size: 1rem,
    $placeholder-color: $text-color-light
) {
    @include input-label-absolute(
        $label-font-size,
        $label-x-offset,
        $label-padding,
        $outer-background-color,
        $inner-background-color,
        $input-border-width
    );
    & > label {
        position: absolute;
        top: -0.5em;
        margin: 0;
        pointer-events: none;
        transition: all .3s;
        &::before {
            transition: .1s .2s background-color; 
        }
    }
    input,
    textarea {
        &:not(:focus):not(.has-value) + label {
            top: get-side($input-padding, top);
            left: get-side($input-padding, left);
            font-size: $input-font-size;
            padding: 0;
            line-height: $input-line-height;
            color: $placeholder-color;
            &::before {
                transition: .1s background-color;
                background-color: $inner-background-color;
            }
        }
    }
}
//this is in need of a markup rewrite
//cms/bundles/SB/fields/views/inline-list.html.twig
@mixin input-list-table(
    $vertical-margin: $baseline,
    $border: 1px solid,
    $border-radius: 0,
    $header-background-color: $off-white,
    $footer-background-color: $header-background-color,
    $body-background-color: $light-gray,
    $body-background-odd-color: $off-white,
    $label-type: absolute,
    $label-options: ()
) {
    margin-top: $vertical-margin;
    table {
        border: $border;
    }
    thead td {
        border-bottom: $border;
    }
    table {
        @if $border-radius > 0 {
            border-collapse: separate;
            border-radius: $border-radius;
            overflow: hidden;
        }
    }
    table {
        width: 100%;
    }
    td {
        padding: $baseline / 2;
        line-height: 1.25;
    }
    thead {
        background-color: $header-background-color;
        font-weight: $bold;
        td {
        }
        td:first-child {
            font-size: 0;
            min-width: 2rem;
        }
    }
    tbody {
        tr {
            background-color: $body-background-color;
            &:nth-child(odd) {
                background-color: $body-background-odd-color;
            }
        }
    }
    tfoot {
        background-color: $footer-background-color;
    }
    .icon-button {
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
    }
    .sb-admin-list-priority,
    .sb-admin-list-delete-button,
    .sb-inline-list-add-button {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
    }
    .sb-admin-list-priority {
        @include sb-icon-move($text-color-regular);
        cursor: move;
    }
    .sb-admin-list-delete-button {
        @include sb-icon-delete($anchor-color);
        background-size: 1.2rem 1.2rem;
        &:hover,
        &:focus {
            @include sb-icon-delete($anchor-color-hover);
        }
    }
    .sb-inline-list-add-button {
        @include sb-icon-plus($white);
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        padding: 0;
        text-indent: 100%;
        overflow: hidden;
        white-space: nowrap;
        color: transparent;
        background-color: $anchor-color;
        &:hover,
        &:focus {
            background-color: $anchor-color-hover;
        }
    }
    .sb-inline-list-new-row td {
        vertical-align: top;
    }
    #{text-inputs()},
    textarea {
        width: 100%;
        border-radius: $border-radius;
    }
    textarea {
        resize: vertical;
    }
    tbody {
    }
    @if $label-type == absolute {
        $border-width: get-border-value($border, width);
        $label-default-options: (
            scale: 0.85,
            x-offset: 0.4rem, 
            padding: .2rem, 
            outer-background-color: $white,
            inner-background-color: transparent
        );
        $label-options: map-merge($label-default-options, $label-options);
        position: relative;
        thead td {
            padding-top: $baseline;
        }
        .sb-section-head {
            z-index: 4;
            position: absolute;
            top: -0.5em;
            left: map-get($label-options, x-offset);
            margin: 0;
            padding: 0 map-get($label-options, padding);
            font-size: #{map-get($label-options, scale)}em;
            line-height: 1;
            background-color: map-get($label-options, inner-background-color);
            &::before {
                z-index: -1;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                //fudge 1 border width to make up for pixel rounding
                bottom: calc(.5em - #{$border-width * 2});
                left: 0;
                right: 0;
                background-color: map-get($label-options, outer-background-color);
            }
        }
    }
}
@mixin input-list-table-stack {
    table,
    thead,
    tbody,
    tfoot,
    td {
        display: block;
    }
    thead td {
        border: none;
    }
    table {
        display: grid;
        grid-template-columns: auto 1fr;
        tbody {
            grid-row: 1;
            grid-column: 1 / span 2;
        }
        thead {
            grid-row: 2;
            grid-column: 1;
        }
        tfoot {
            grid-row: 2;
            grid-column: 2;
        }
    }
    tbody tr {
        display: grid;
        grid-template-columns: 2rem 1fr auto;
        padding-top: $baseline / 2;
        padding-bottom: $baseline / 2;
        td {
            grid-column: 2;
            line-height: 1;
            padding-top: $baseline / 3;
            padding-bottom: $baseline / 3;
        }
        td:first-child {
            grid-row: 1 / span 10;
            grid-column: 1;
            padding: 0;
        }
        td:last-child {
            grid-row: 1 / span 10;
            grid-column: 3;
            // padding: 0;
            display: flex;
            align-items: center;
        }
    }
    thead,
    tfoot {
        padding-bottom: $baseline / 2;
        td:first-child,
        td:empty {
            display: none;
        }
        td {
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            margin-top: $baseline / 2;
        }
        #{text-inputs()} {
            height: $baseline * 3;
        }
    }
    thead td {
        height: $baseline * 3;
    }
    tfoot {
        tr {
            display: grid;
            grid-template-columns: 1fr auto;
            td {
                grid-column: 1;
            }
            td:last-child {
                grid-column: 2;
            }
        }
    }
    // .sb-inline-list-add-button {
    //     background-image: none;
    //     width: auto;
    //     height: auto;
    //     padding: $baseline / 2;
    //     text-indent: unset;
    //     overflow: unset;
    //     white-space: unset;
    //     color: $text-color-white;
    // }
}
@mixin input-switch (
    $checked-color: $primary-color,
    $unchecked-color: lighten(desaturate($primary-color, 70%), 40%),
    $text-size: 1rem
) {
    input[type="checkbox"] {
        position: absolute;
        width: 0px;
        height: 0px;
        margin: 0;
        overflow: hidden;
        opacity: 0;
    }
    label {
        display: flex;
        align-items: flex-start;
        margin: 0;
        line-height: 1.4;
        font-size: $text-size;
    }
    .switch-slider {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        width: 2.4em;
        height: 1.4em;
        // margin-top: .05em;
        margin-right: .5em;
        border-radius: .7em;
        background-color: $unchecked-color;
        transition: background-color .2s ease;
        &::before {
            content: '';
            flex-grow: 0;
            width: 0px;
            transition: flex-grow .2s ease;
        }
        &::after {
            content: '';
            flex-shrink: 0;
            width: 1em;
            height: 1em;
            margin: .2em;
            background-color: $white;
            border-radius: 50%;
        }
    }
    [type="checkbox"]:checked ~ label .switch-slider {
        background-color: $primary-color;
        &::before {
            flex-grow: 1;
        }
    }
    label {
        &::before {
            display: none;
        }
    }
    &.label-before {
        .switch-slider {
            margin-right: 0;
            margin-left: .5em;
        }
    }
}
//not ie11 safe
@mixin input-reset($adjacency: false) {
    select,
    input,
    textarea,
    label,
    fieldset,
    legend {
        z-index: unset;
        position: unset;
        display: unset;
        width: unset;
        min-width: unset;
        height: unset;
        min-height: unset;
        margin: unset;
        padding: unset;
        border: unset;
        outline: unset;
        opacity: unset;
        background: unset;
        overflow: unset;
        &::before,
        &::after {
            content: unset;
            display: unset;
        }
    }
    @if $adjacency == true {
        select,
        textarea,
        input {
            & + label {
                z-index: unset;
                position: unset;
                display: unset;
                width: unset;
                min-width: unset;
                height: unset;
                min-height: unset;
                margin: unset;
                padding: unset;
                border: unset;
                outline: unset;
                opacity: unset;
                background: unset;
                overflow: unset;
                &::before,
                &::after {
                    content: unset;
                    display: unset;
                }
            }
        }
    }
}
@mixin button-base(
    $padding: strip-unit($baseline) * 3em / 4 1em,
    $border-radius: .3rem,
    $border-width: 1px,
    $min-width: 6rem,
    $font-size: 1rem,
    $line-height: strip-unit($baseline) * 2em
) {
    display: inline-block;
    min-width: $min-width;
    -webkit-appearance: none;
    line-height: $line-height;
    text-align: center;
    font-size: $font-size;
    cursor: pointer;
    padding-top: calc(#{get-side($padding, top)} - #{$border-width};
    padding-right: calc(#{get-side($padding, right)} - #{$border-width};
    padding-bottom: calc(#{get-side($padding, bottom)} - #{$border-width};
    padding-left: calc(#{get-side($padding, left)} - #{$border-width};
    border: $border-width solid;
    border-radius: $border-radius;
    transition: background .2s ease,
                color .2s ease,
                border-color .2s ease;
    &.disabled,
    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
}
@mixin button-color($key, $hover-only: false) {
    $colors: map-get($button-colors, $key);
    @if $hover-only {  
        background: map-get($colors, background-hover);
        color: map-get($colors, text-hover);
        border-color: map-get($colors, border-hover);
    } @else {
        background: map-get($colors, background);
        color: map-get($colors, text);
        border-color: map-get($colors, border);
        &:hover,
        &:focus {
            background: map-get($colors, background-hover);
            color: map-get($colors, text-hover);
            border-color: map-get($colors, border-hover);
        }
    }
}
