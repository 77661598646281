@import "@depot/grid";

@mixin slideshow(
    $nav-button-placeholder: "%slide-nav-default",
    $nav-button-hover-placeholder: "%slide-nav-hover-default"
) {
    @include slideshow-navigation-buttons($nav-button-placeholder, $nav-button-hover-placeholder);
    .transition-fade {
        @include slideshow-animation-fade;
    }
    .transition-push {
        @include slideshow-animation-translate;
    }
}

@mixin slideshow-navigation-buttons(
    $nav-button-placeholder: "%slide-nav-default",
    $nav-button-hover-placeholder: "%slide-nav-hover-default"
) {
    .slideshow-cont {
        position: relative;
    }
    .slideshow-stage {
        position: relative;
        overflow: hidden;
    }
    .slideshow-buttons {
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .slideshow-previous,
    .slideshow-next {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20%;
        position: absolute;
        top: 0%;
        right:0;
        bottom: 0%;
        // &::before {
        //     content: "";
        //     // z-index: -1;
        //     display: block;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     opacity: 0;
        //     background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.2));
        //     transition: opacity .3s ease;
        // }
        &::after {
            content: "";
            display: block;
            position: relative;
            @extend #{$nav-button-placeholder};
        }
    }
    .slideshow-stage:hover {
        .slideshow-buttons {
            display: block;
        }
    }
    .slideshow-next:hover,
    .slideshow-previous:hover {
        cursor: pointer;
        // &::before {
        //     opacity: 1;
        // }
        &::after {
            @extend #{$nav-button-hover-placeholder};
        }
    }
    .slideshow-previous {
        transform: rotate(180deg);
        left: 0;
        right: auto;
    }
    .slideshow-cont.slideshow-single,
    .slideshow-cont.is-touch {
        .slideshow-buttons {
            display: none;
        }
    }
    .slideshow-thumbnail-list {
        ul {
            padding-left: 0;
            line-height: 0;
            li {
                display: inline-block;
            }
            li:hover {
                cursor: pointer;
            }
        }
    }
    %slide-nav-default {
        height: 72px;
        width: 36px;
        margin: 1rem;
        background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="144" height="240" viewBox="0 0 144 240"><style>.cls-1 {opacity: 0.3;transform-origin: center;transform: scale(1.02) translate(-3px, 0);}.cls-2 {fill: #fff;}</style><filter id="shadow"><feGaussianBlur in="SourceGraphic" stdDeviation="3"/></filter><title>slide-nav</title><path filter="url(#shadow)" class="cls-1" d="M106.39,123.26l.07-.17c.08-.22.15-.45.22-.68l0-.15c.06-.22.11-.45.15-.67l0-.2q0-.3.07-.6c0-.11,0-.22,0-.32s0-.31,0-.47,0-.31,0-.47,0-.22,0-.32,0-.4-.07-.6l0-.2c0-.23-.09-.45-.15-.67l0-.15c-.06-.23-.14-.45-.22-.68l-.07-.17c-.08-.21-.17-.41-.26-.62l-.13-.25c-.07-.13-.12-.25-.19-.38l-51-88.3a9,9,0,0,0-15.6,9L87.61,120,39.2,203.8a9,9,0,0,0,15.6,9l51-88.3c.07-.12.13-.25.19-.38l.13-.25C106.23,123.67,106.31,123.46,106.39,123.26Z" transform="translate(0 0)"/><path class="cls-2" d="M106.39,123.26l.07-.17c.08-.22.15-.45.22-.68l0-.15c.06-.22.11-.45.15-.67l0-.2q0-.3.07-.6c0-.11,0-.22,0-.32s0-.31,0-.47,0-.31,0-.47,0-.22,0-.32,0-.4-.07-.6l0-.2c0-.23-.09-.45-.15-.67l0-.15c-.06-.23-.14-.45-.22-.68l-.07-.17c-.08-.21-.17-.41-.26-.62l-.13-.25c-.07-.13-.12-.25-.19-.38l-51-88.3a9,9,0,0,0-15.6,9L87.61,120,39.2,203.8a9,9,0,0,0,15.6,9l51-88.3c.07-.12.13-.25.19-.38l.13-.25C106.23,123.67,106.31,123.46,106.39,123.26Z" transform="translate(0 0)"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: .5;
    }
    %slide-nav-hover-default {
        opacity: 1;
    }
}
@mixin slideshow-animation-fade() {
    .items {
        @include grid;
        @include grid-template-rows(1fr);
        @include grid-template-columns(1fr);
    }
    .slide {
        @include grid-column(1);
        @include grid-row(1);
        @include grid-align-self(stretch);
        transition: opacity .6s ease;
        position: relative;
        opacity: 1;
    }
    .slide.last-slide {
        opacity: 0;
        z-index: 2;
        pointer-events: none;
    }
    .slide.current-slide {
        z-index: 1;
    }
    .slideshow-previous,
    .slideshow-next {
        z-index: 3;
    }
}
@mixin slideshow-animation-translate() {
/*
    .slideshow-multiple .items{
        transform: translateX(-100%);
    }
*/
    .items {
        will-change: transform;
        display: flex;

        // @include item-group(
        //     $base: true,
        //     $baseline: 0,
        //     $max-width: none,
        //     $per-row: (
        //         small: 1
        //     ),
        //     $gutters: (
        //         small: 0
        //     ),
        //     $wrap: false
        // );
    } 
    .item {
        flex-basis: 100%;
        max-width: 100%;
        flex-shrink: 0;
    }
}

@mixin slideshow-old(
    $nav-button-placeholder: "%slide-nav-default",
    $nav-button-hover-placeholder: "%slide-nav-hover-default"
) {
    @include slideshow-navigation-buttons(
        $nav-button-placeholder, 
        $nav-button-hover-placeholder
    );

    .slideshow-multiple .items{
        transform: translateX(-100%);
    }

    .items {
        will-change: transform;
        @include item-group(
            $base: true,
            $baseline: 0,
            $max-width: none,
            $per-row: (
                small: 1
            ),
            $gutters: (
                small: 0
            ),
            $wrap: false
        );
    } 
}

@mixin slideshow-navigation-images(
    $thumbnail-size: (
        small: $baseline * 3,
        medium: $baseline * 4,
        large: $baseline * 6
    ),
    $gutter: (
        small: #{2 / $em}rem,
    )
) {
    ul {
        // padding: $gutter / 8;
        line-height: 0;
        @include breakout($gutter) {
            padding: $value;
        }
        @supports (display: grid) {
            display: grid;
            width: 100%;
            justify-content: center;
            @include breakout($thumbnail-size) {
                grid-template-columns: repeat(auto-fit, #{$value});
            }
        }
    }
    li {
        @include object-fit;
        @include breakout($gutter) {
            padding: $value;
        }
        @include breakout($thumbnail-size) {
            width: $value;
            height: $value;
        }
        display: inline-flex;
        cursor: pointer;
        img {
            transition: filter .1s;
        }
        &.current-slide {
            img {
                filter: brightness(0.6);
            }
        }
        &:hover,
        &:focus {
            img {
                filter: brightness(1.3);
            }
        }
    }
}

@mixin slideshow-navigation-pips(
    $pip-hitbox: 24px,
    $pip-size: 8px,
    $pip-margin: 0,
    $pip-color: rgba($white, .6),
    $pip-color-active: $white,
    $pip-shadow: 0 0 0 1px rgba(0,0,0,0.1),
    $pip-shadow-active: $pip-shadow
) {
    ul {
        display: flex;
        justify-content: center;
    }
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $pip-hitbox;
        height: $pip-hitbox;
        margin: $pip-margin;
        cursor: pointer;
        &::before {
            content: '';
            display: block;
            width: $pip-size;
            height: $pip-size;
            border-radius: 50%;
            background-color: $pip-color;
            box-shadow: $pip-shadow;
            transition: background-color .2s ease,
                        box-shadow .2s ease;
        }
        &.current-slide {
            &::before {
                box-shadow: $pip-shadow-active;
                background-color: $pip-color-active;
            }
        }
    }
}
