@import 'parent:';

@mixin button(
) {
    @include button-base(
        $padding: 1em 2em,
        $border-radius: 4em
    );
    color: inherit;
    text-transform: uppercase;
    @include button-color('base');
    &[type="submit"],
    &.submit {
        @include button-color('fill');
    }
}
