@mixin page-layout-base {
    @include grid;
    @include grid14;
    @include grid-gap($gutter * 2);
    .secondary {
        display: none;
    }
    .header-banner {
        @include grid-row(1);
        @include grid-column(1, 14);
        // @include breakout($outer-gutters) {
        //     padding-left: $value;
        //     padding-right: $value;
        // }
    }
    .primary {
        @include grid-row(2);
        @include grid-column(1, 12, true);
    }
    @include breakpoint($secondary-breakpoint-up up) {
        .secondary,
        .primary {
            @include grid-row(2);
        }
        .secondary {
            display: block;
            @include grid-column(1, 3, true);
        }
        .primary {
            max-width: $site-width * 8/12;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .secondary + .primary {
            @include grid-column(4, 9, true);
            max-width: none;
        }
    }
}
@mixin page-layout-minimal {
    .primary {
        @include grid-column(1, 12, true);
        // text-align: center;
        width: 100%;
        max-width: $content-width;
        margin-left: auto;
        margin-right: auto;
    }
    .item-group {
        margin-left: auto;
        margin-right: auto;
    }
    @include breakpoint($secondary-breakpoint-up up) {
        .primary {
            @include grid-column(1, 12, true);
        }
    }
}
@mixin page-layout-home {
    display: block;
    @include grid-gap(0);
    .primary {
        max-width: none;
    }
}