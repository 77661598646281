@mixin clear-fix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }
    &::after {
        clear: both;
    }
}
@mixin unstyled-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
@mixin object-fit($options: ()) {
    
    $defaults: (
        object-fit: cover,
        object-position: null,
        display: block,
        width: 100%,
        height: false,
        height-large: false,
        //for pollyfill
        font-family: 'object-fit: cover',
    );

    $options: map-merge($defaults, $options);
    @if map-get($options, object-fit) != cover {
        $options: map-merge($options, (font-family: 'object-fit: ' + map-get($options, object-fit)));
    }
    img {
        
        @each $key, $value in (
            object-fit: object-fit,
            // object-position: object-position,
            display: display,
            width: width,
            height: height,
            //for use with ofi.browser.js pollyfill
            font-family: font-family,
        ) {
            @if map-get($options, $value) != false {
                #{$key}: map-get($options, $value);
            }
        }
        @if map-get($options, object-position) != null {
            $object-position-x: nth(map-get($options, object-position), 1);
            $object-position-y: center;
            @if length(map-get($options, object-position)) > 1 {
                $object-position-y: nth(map-get($options, object-position), 2);
            }
            object-position: var(--position-left, #{$object-position-x}) var(--position-top, #{$object-position-y});
        } @else {
            object-position: var(--position-left) var(--position-top);
        }
        @include breakpoint(large up) {
                @each $key, $value in (
                    height: height-large,
                ) {
                    @if map-get($options, $value) != false {
                        #{$key}: map-get($options, $value);
                    }
                }
            }
    }

}
