@import '@depot/utility';
@import 'site:variables';
@import 'mixins';
.item-group.slideshow {
    @include slideshow;
}

.header-banner {
    .sb-display-point-first.slideshow {
        margin-top: 0;
        margin-bottom: $baseline * 2;
        min-height: $baseline * 3;
    }
    .lister.slideshow {
        @include banner-slideshow-fixed;
    }
    .slideshow .sb-display-point-edit-buttons {
        top: auto;
        bottom: 1px;
    }
}

.slideshow-navigation {
    @include slideshow-navigation-images;
}
