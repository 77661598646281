@import 'parent:';
$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 668px,
  mobileMenu: 840px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
);
$outer-gutters: (
    small: $gutter,
    medium: $gutter * 2
);
$mobile-breakpoint-up: mobileMenu;
$mobile-breakpoint-down: medium;

$secondary-breakpoint-up: mobileMenu;
$secondary-breakpoint-down: large;


$header-logo-height: (
    small: $baseline * 3,
    $mobile-breakpoint-up: $baseline * 4,
);
$sticky-header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: 0,
);
$header-offset: (
    small: 0,
    $mobile-breakpoint-up: $baseline * 2,
);
$header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: $baseline * 8,
);

$header-banner-height: (
    small: #{240/$em}rem,
    medium: #{320/$em}rem,
);

$typography: (
    header-base: (
        font-family: $header-font-family,
        font-weight: $semibold,
        line-height: 1.2,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
    ),
    header-b: (
        base: header-base,
        small: (
            font-weight: $normal,
            font-size: #{20/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: #{24/$em}rem,
            line-height: $baseline * 2.5,
        ),
    ),
    header-a: (
        base: header-base,
        small: (
            font-weight: $normal,
            font-size: #{24/$em}rem,
            line-height: $baseline * 2.5,
        ),
        medium: (
            font-size: #{36/$em}rem,
            line-height: $baseline * 3.5,
        ),
    ),
    home-header-a: (
        base: header-b,
    ),
    home-header-b: (
        base: header-a,
    ),
    home-description-text: (
        base: text-base,
        font-weight: $light,
        small: (
            font-size: #{20/$em}rem,
            line-height: $baseline * 2.5,
        ),
        medium: (
            font-size: #{24/$em}rem,
            line-height: $baseline * 3,
        ),
    ),
    home-summary-text: (
        base: home-description-text,
    ),
    home-rte-text: (
        base: text-base,
        small: (
            font-size: #{18/$em}rem,
        ),
        medium: (
            font-size: #{20/$em}rem,
        ),
    ),
);

