@import 'site:variables';
@import 'site:icon-mixins';
@import 'mixins';
.site {
    .input {
        @include input-base($vertical-margin: $baseline * 2);
        @include input-label-absolute;
        @include input-colors;
    }
    .input.label-placeholder {
        @include input-label-placeholder;
    }
}
.button {
    @include button;
}
input,
textarea {
    border: 1px solid $light-gray;
    outline: 0;
    &:focus {
        border: 1px solid $anchor-color;
    }
}
