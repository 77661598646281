@import 'parent:';

.header-inner {
    .nav-social {
        @include icon-size(rem-calc(36, $em), false, rem-calc(28, $em));
        li {
            display: flex;
            align-items: center;
        }
    }
    @include breakpoint($mobile-breakpoint-up up) {
        @include grid-template-rows(1fr $baseline * 4);
        .logo {
            @include grid-row(1);
        }
        .nav-social {
            @include icon-size(rem-calc(36, $em), false, rem-calc(24, $em));
            @include grid-row(1, 1);
            @include grid-column(3, 1);
            & > li {
                margin-left: 0;
            }
        }
        .search-cont {
            @include grid-row(1, 1);
            @include grid-column(4, 1);
        }
        .primary-navigation {
            @include grid-row(2, 1);
            @include grid-column(1, 4);
            @include grid-align-self(stretch);
            @include grid-justify-self(stretch);
        }
        .primary-navigation,
        .primary-navigation li,
        .primary-navigation a:not(.nav-section-title) {
            display: flex;
        }
        .primary-navigation a {
            align-items: center;
        }
    }
    @include breakpoint(large up) {
        @include grid-template-rows(1fr 1fr)
        .logo {
            @include grid-row(1, 2);
        }
        .nav-social {
            @include grid-column(3, 2);
        }
        .search-cont {
            @include grid-row(2, 1);
        }
        .primary-navigation {
            @include grid-column(2, 2);
            @include grid-align-self(stretch);
        }
    }
}

.primary-navigation {
    @include breakpoint($mobile-breakpoint-up up) {
        .nav-primary {
            flex-grow: 1;
            justify-content: space-around;
        }
        li:not(:first-child) {
            margin-left: $baseline;
        }
        .is-active > a {
            box-shadow: inset 0 -2px 0 0 currentColor;
        }
    }
    @include breakpoint(large up) {
        .nav-primary {
            justify-content: flex-end;
        }
    }
    @include breakpoint(1100px up) {
        li:not(:first-child) {
            margin-left: $baseline * 2;
        }
    }
}

