// -------------------------------------------------------
// Sizes
// -------------------------------------------------------
$em: 16;
$baseline: .75rem; //12px
$gutter: 12px;

$site-width: 1200px;

$content-width: 720px;

//height of sticky header
// $header-height: (
//     small: $baseline * 5,
//     medium: $baseline * 6
// );
$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
);
$breakpoint-classes: (small smallplus medium large xlarge xxlarge);

// -------------------------------------------------------
// Colors
// -------------------------------------------------------
$green: #a6b639;
$teal: #5ababa;
$purple: #a492c6;
$orange: #f4783e;
$red: #D50603; //error color

$white: #fff;
$off-white: #eee;
$light-gray: #ccc;
$medium-gray: #555;
$gray: #999;
$dark-gray: #333;
$black: #000;

$primary-color: $green; //green
$secondary-color: $orange; //orange

$alert-color: $red;

$text-color-white: $white;
$text-color-light: $gray;
$text-color-regular: $dark-gray;
$text-color-dark: $black;

$anchor-color: $secondary-color;
$anchor-color-hover: darken($secondary-color, 15%);

$body-font-family: sans-serif; 
$header-font-family: sans-serif;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
