@mixin google-translate(
    $popup-x: left,
    $popup-y: top,
    $popup-offset: $baseline / 2,
    $popup-padding: $baseline,
    $anchor-color: $anchor-color-hover,
    $background-color: $off-white
) {
    @include google-translate-position($popup-x, $popup-y, $popup-offset);
    .google-translate-popup-button {
        cursor: pointer;
        padding-right: 1.5em;
        background-size: 1em 1em;
        background-position: right .2em center;
        background-repeat: no-repeat;
    }
    .google-translate-container {
        position: relative;
        margin-right: -1.5em;
        .icon {
            display: flex;
        }
    }
    .google-translate-popup {
        z-index: 20;
        position: absolute;
        min-width: 100%;
        padding: $popup-padding;
        background-color: $background-color;
        transform: scaleY(0);
        transition: transform .3s ease;
    }
    .google-translate-element {
        opacity: 0;
        transition: opacity .1s ease;
    }
    .google-translate-container.is-active {
        .icon {
            @extend .is-active !optional;
        }
        .google-translate-popup-button {
            @include sb-icon-close($anchor-color);
        }
        .google-translate-popup {
            transform: scaleY(1);
        }
        .google-translate-element {
            opacity: 1;
            transition: opacity .1s .2s ease;
        }
    }
    .goog-te-gadget {
        select.goog-te-combo {
            width: auto;
            margin-top: 0;
            margin-bottom: $popup-padding;
            padding: $baseline / 2;
        }
    }
    /* Hide the auto-translate popup */
    .goog-tooltip {
        display: none !important;
    }

    .goog-tooltip:hover {
        display: none !important;
    }

    .goog-text-highlight {
        background-color: transparent !important;
        border: none !important; 
        box-shadow: none !important;
    }
}
@mixin google-translate-position(
    $x: left,
    $y: top,
    $offset: $baseline / 2
) {
    .google-translate-popup {
        margin: 0;
        @if $x == left {
            left: 0;
            right: auto;
        } @else {
            left: auto;
            right: 0;
        }
        @if $y == top {
            top: 100%;
            bottom: auto;
            margin-top: $offset;
        } @else {
            top: auto;
            bottom: 100%;
            margin-bottom: $offset;
        }
        transform-origin: #{$x} #{$y};
    }
    .google-translate-element {
    }
}
