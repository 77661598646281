@include rte-content;
body {
    @include typography('text-medium');
    color: $text-color-regular;
    font-family: $body-font-family;
}
a {
    color: $anchor-color;
    text-decoration: none;
    
    &:focus,
    &:hover {
        color: $anchor-color-hover;
    }
}
strong,
b {
    font-weight: $bold;
}
ul {
    margin: 0;
}
p {
    margin: $baseline 0;
}
h1{
    margin: 0;
    @include typography('header-a');
}
h2 {
    margin: 0;
    @include typography('header-b');
}
h3 {
    margin: 0;
    @include typography('header-c');
}
h4 {
    margin: 0;
    @include typography('header-d');
}
.text-upper {
    text-transform: uppercase;
}
.text-nowrap {
    display: inline-block;
    margin-right: .5em;
}
.rte a {
    text-decoration: underline;
}
