@import "site:variables";
@import 'site:icon-mixins';
@import "@depot/breakpoint";
@import "mixins";

.google-translate-container {
    display: none;
}
@include google-translate(right, bottom, $popup-offset: $baseline);

@include breakpoint($mobile-breakpoint-up up) {
    @include google-translate-position(right, top, $baseline / 2);
    .google-translate-container {
        display: block;
    }
}
