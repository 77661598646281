.item-group,
.sb-display-point-first {
    margin-top: $baseline * 2;
    &:first-child {
        margin-top: $baseline * 3;
    }
}
.item-group.page {
    width: 100%;
    max-width: $content-width;
    // margin-left: auto;
    // margin-right: auto;
    & > * {
        margin-top: $baseline;
        &:first-child {
            margin-top: 0;
        }
    }
    .date-posted {
        @include typography('text-small');
        // margin-top: 0;
        padding-top: $baseline / 2;
        border-top: 1px solid $light-gray;
        &::before {
            content: 'Posted: ';
            display: inline;
        }
    }
}
.item-wrapper {
    margin-bottom: $baseline;
    &:empty {
        display: none;
    }
}
.item-group.secondary-navigation {
    @include side-nav-basic;
    margin-top: $baseline * 7;
}
.item-group.sitemap {
    @include sitemap-basic;
    @include typography('text-small');
    a {
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .nav-primary > li > a {
        @include typography('header-d');
    }
}
.item-group.pagination-page {
    @include pagination;
}