@mixin flex-columns(
    $gutter: $gutter,
    $baseline: $baseline,
    $per-row: (
        small: 1,
        medium: 2,
        large: 3,
    ),
    $shrink-wrap: false,
) {
    display: flex;
    flex-wrap: wrap;
    @if $shrink-wrap {
        @include breakout($baseline) {
            margin-top: $value * -2;
        }
        @include breakout($gutter) {
            margin-left: -#{$value};
            margin-right: -#{$value};
        }
    }
    & > * {
        @include breakout($baseline) {
            margin-top: $value * 2;
        }
        @include breakout($per-row) {
            $this-gutter: get-breakpoint-value($gutter, $breakpoint);
            @include flex-column(1, $this-gutter, $value);
        }
    }
}
@mixin flex-column($column-span: 1, $gutter: $gutter, $column-count: 12) {
    width: calc(#{percentage($column-span / $column-count)} - #{$gutter * 2});
    flex-basis: calc(#{percentage($column-span / $column-count)} - #{$gutter * 2});
    margin-left: $gutter;
    margin-right: $gutter;
}
