@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/typography';
// @import 'site:variables';
@import 'icons';
$baseline: .75rem !default;

@mixin article-icons($color, $hover-color: null, $size: $baseline * 2, $background-size: $size) {
    $hover-color: $color !default;
    .head::after {
        // position: absolute;
        // box-sizing: content-box;
        // top: 0;
        // right: 0;
        // padding: .5rem;
        background-position: center;
        background-repeat: no-repeat;
        @include breakout($size) {
            width: $value;
            height: $value;
        }
        @include breakout($background-size) {
            background-size: $value $value;
        }
        // transition: background-image .2s ease;
    }
    .item:not(.none) .head::after {
        content: '';
        display: block;
    }
    .item.expander {
        .head::after {
            @include icon-plus($color);
        }
        a:hover:not(.is-expanded),
        a.is-active:not(.is-expanded)  {
            .head::after {
                @include icon-plus($hover-color);
            }
        }
        .is-expanded .head::after {
            @include icon-minus($color);
        }
        .is-expanded:hover .head::after {
            @include icon-minus($hover-color);
        }
    }
    .item.page,
    .item.link,
    .item.issue {
        .head::after {
            @include icon-arrow($color);
        }
        a:hover,
        a.is-active {
            .head::after {
                @include icon-arrow($hover-color);
            }
        }
    }
    .item.download {
        .head::after {
            @include icon-download($color);
        }
        a:hover,
        a.is-active  {
            .head::after {
                @include icon-download($hover-color);
            }
        }
    }
}
@mixin basic-article-list(
    $image-size: (
        small: $baseline * 2.5,
        smallplus: $baseline * 4,
        medium: $baseline * 6
    ),
    $banner-size: (
        small: #{120/$em}rem,
        smallplus: #{240/$em}rem,
    ),
    $baseline: $baseline,
    $gutter: (
        small: $baseline,
        medium: $baseline * 2,
    ),
    $content-width: none,
    $compact-breakpoint: false
) {
    a.head-link {
        color: inherit;
        text-decoration: inherit;
    }
    .item,
    .head-link {
        display: block;
    }
    .image {
        @include object-fit;
        display: flex;
        img,
        svg {
            width: 100%;
        }
    }
    .head {
        display: flex;
        padding-top: $baseline * 1.5;
        padding-bottom: $baseline * 1.5;
        .image {
            flex-shrink: 0;
            @include breakout($image-size) {
                width: $value;
                height: $value;
            }
            svg {
                width: 100%;
            }
        }
        .text-group {
            flex-basis: 50%; //ie11
            flex-grow: 1;
            & > * {
                margin-top: $baseline / 2;
            }
            & > *:empty {
                display: none;
            }
            & > *:first-child {
                margin-top: 0;   
            }
        }
        &::after {
            flex-shrink: 0;
            margin-left: auto;
        }
        .title {
            @include typography('header-c');
        }
        .summary {
            @include typography('text-large');
        }
    }
    .head .text-group,
    .expander-bodyu > * {
        max-width: $content-width;
        padding-left: 0;
        @include breakout($gutter) {
            padding-right: $value;
        }
    }
    .image + .text-group {
        @include breakout($gutter) {
            padding-left: $value;
        }
    }
    .expander-body-cont {
    }
    .expander-bodyu {
        padding-bottom: $baseline * 1.5;
        & > * {
            margin-top: $baseline * 1.5;
            &:first-child {
                margin-top: 0;
            }
        }
        & > .image {
            @include breakout($banner-size) {
                height: $value;
            }
        } 
    }
    .has-image .expander-bodyu {
        & > * {
            @include breakout($gutter) {
                padding-left: $value;
            }
        }
        @include breakout($image-size) {
            margin-left: calc(#{$value});
        }
    }
    .video-cont {
        position: relative;
        width: 100%;
        padding-bottom: percentage(9/16);
        iframe.video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
    @include breakout($gutter) {
        iframe.video {
            // padding-right: $value;
        }
        .has-image iframe.video {
            padding-left: $value;
        }
    }            
    //this is focus-able in chrome for some reason
    .expander-bodyu:focus {
        outline: none;
    }
    @if $compact-breakpoint != false {
    @supports ((display: contents) and (display: grid)) {
        @include breakpoint($compact-breakpoint down) {
            .has-image .head {
                $smallGutter: -zf-get-bp-val($gutter, $compact-breakpoint);
                display: grid;
                grid-template-columns: auto 1fr auto;
                grid-column-gap: $smallGutter;
                // grid-row-gap: $baseline;
                padding-left: $smallGutter / 2;
                padding-right: $smallGutter / 2;
                align-items: center;
                .text-group {
                    display: contents;
                }
                .image {
                    grid-column: 1;
                    grid-row: 1;
                }
                .title {
                    grid-row: 1;
                    grid-column: 2;
                    margin: 0;
                }
                .summary,
                .date-posted {
                    grid-column: 1 / span 3;
                    margin: 0;
                    padding: 0 $smallGutter / 2;
                }
                .summary {
                    grid-row: 2;
                    margin-top: $baseline;
                }
                .date-posted {
                    grid-row: 3;
                    grid-column: 1 / span 3;
                    margin-top: $baseline / 2;
                    font-size: .8em;
                    &::before {
                        content: 'Posted: ';
                    }
                }
                &::after {
                    grid-row: 1;
                    grid-column: 3;
                }
            }
            .has-image .expander-bodyu {
                margin-left: 0;
            }
            .has-image iframe.video {
                padding-left: 0;
            }
        }       
    }
    }
    .item.is-active {
        display: none;
    }
}
