@import 'parent:';

$site-width: 1300px;

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 668px,
  mobileMenu: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
);
// $mobile-breakpoint-up: medium;
// $mobile-breakpoint-down: smallplus;

$outer-gutters: (
    small: $gutter,
    large: $gutter * 2
);
$header-logo-height: (
    small: $baseline * 3,
    $mobile-breakpoint-up: $baseline * 4,
    large: $baseline * 7
);
$header-offset: (
    small: 0,
    $mobile-breakpoint-up: $baseline,
    large: $baseline * 3
);

$sticky-header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: 0,
    large: 0,
);
$header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: $baseline * 9,
    large: $baseline * 12
);
$primary-color: #80bc00; //green
$secondary-color: #007b9a; //blue

$primary-color-light: #9fda22;
// $white: #fff;
$off-white: #eeefed;
// $light-gray: #ccc;
$gray: #9B9B9B;
$dark-gray: #494949;
// $black: #000;

$footer-bg: #244957;
$footer-color: #fff;

// $text-color-white: $white;
$text-color-light: $gray;
$text-color-regular: $dark-gray;
// $text-color-dark: $black;

$anchor-color: $primary-color;
$anchor-color-hover: $secondary-color;

$banner-background-color: $secondary-color;
$banner-text-color: $text-color-white;

$body-font-family: 'Montserrat', sans-serif;
$header-font-family: $body-font-family;

$available-icons: facebook twitter linkedin translate home;

//need to replace the header font family into the typography map
$typography: map-merge($typography, (
    header-base: (
        font-family: $header-font-family,
        font-weight: $semibold,
        line-height: 1.2,
    ),
    header-c: (
        base: header-base,
        small: (
            font-size: #{18/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: #{20/$em}rem,
            line-height: $baseline * 2,
        ),
        // large: (
        //     font-size: #{24/$em}rem,
        //     line-height: $baseline * 2.5,
        // ),
    ),
    header-b: (
        base: header-base,
        small: (
            // font-weight: $bold,
            font-size: #{20/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: #{28/$em}rem,
            line-height: $baseline * 3,
        ),
        // xlarge: (
        //     font-size: #{40/$em}rem,
        //     line-height: #{48/$em}rem,
        // ),
    ),
    header-a: (
        base: header-base,
        small: (
            // font-weight: $bold,
            font-size: #{24/$em}rem,
            line-height: $baseline * 2.5,
        ),
        medium: (
            font-size: #{36/$em}rem,
            line-height: $baseline * 3.5,
        ),
        // xlarge: (
        //     font-size: #{60/$em}rem,
        //     line-height: #{72/$em}rem,
        // ),
    ),
    home-header-a: (
        base: header-base,
        small: (
            // font-weight: $bold,
            font-size: #{30/$em}rem,
            line-height: $baseline * 3.5,
        ),
        medium: (
            font-size: #{40/$em}rem,
            line-height: $baseline * 4.5,
        ),
        xlarge: (
            font-size: #{60/$em}rem,
            line-height: #{80/$em}rem,
        ),
    ),
    home-header-b: (
        base: header-b,
        xlarge: (
            font-size: #{36/$em}rem,
            line-height: #{42/$em}rem,
        ),
    ),
    home-description-text: (
        base: text-base,
        font-weight: $light,
        small: (
            font-size: #{18/$em}rem,
            line-height: $baseline * 2.5,
        ),
        medium: (
            font-size: #{24/$em}rem,
            line-height: $baseline * 3,
        ),
        // xlarge: (
        //     font-size: #{24/$em}rem,
        //     line-height: $baseline * 3,
        // ),
        // large: (
        //     font-size: #{28/$em}rem,
        //     line-height: $baseline * 3.5,
        // ),
    ),
    home-summary-text: (
        base: home-header-a,
    ),
    home-rte-text: (
        base: text-base,
        small: (
            font-size: #{18/$em}rem,
            line-height: $baseline * 2,
        ),
        medium: (
            font-size: #{20/$em}rem,
            line-height: $baseline * 2,
        ),
        // large: (
        //     font-size: #{24/$em}rem,
        //     line-height: $baseline * 2.5,
        // ),
    ),
));

$button-colors: (
    'base': (
        background: transparent,
        text: $text-color-regular,
        border: $text-color-regular,
        background-hover: $primary-color,
        text-hover: $text-color-white,
        border-hover: $text-color-regular,
    ),
    'white': (
        background: transparent,
        text: $text-color-white,
        border: $text-color-white,
        background-hover: $primary-color,
        text-hover: $text-color-white,
        border-hover: $text-color-white,
    ),
    'fill': (
        background: $anchor-color,
        text: $text-color-white,
        border: $anchor-color,
        background-hover: $anchor-color-hover,
        text-hover: $text-color-white,
        border-hover: $anchor-color-hover,
    ),
);