@import 'variables';
@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/typography';
@import '@depot/rte-content';
@import 'forms:mixins';
@import 'mixins';

// $home-content-width: $site-width;

$home-section-padding: (
    small: $baseline * 3,
    medium: $baseline * 6,
    large: $baseline * 8,
    // xlarge: $baseline * 12
);

$home-section-inner-padding: (
    small: $baseline * 2,
    medium: $baseline * 4,
    large: $baseline * 5,
    xlarge: $baseline * 6
);

$home-section-half-padding: (
    small: $baseline * 3,
    medium: $baseline * 4,
    large: $baseline * 6
);

@mixin home-section-white {
    color: $text-color-white;
    .button {
        @include button-color('white');
    }
    .rte a {
        color: $text-color-white;
        &:hover,
        &:focus {
            color: $anchor-color;
        }
    }
    .item-group.callouts-2,
    .item-group.callouts-3 {
        a.item {
            color: $text-color-white;
            svg {
                fill: $text-color-white;
            }
        }
        a.item:hover,
        a.item:focus {
            // color: $anchor-color-hover;
            .button {
                @include button-color('white', true);
            }
            svg {
                fill: $anchor-color;
            }
        }
    }
}
.item-group.home-section {
    @include home-section($home-section-padding, $home-section-inner-padding);
    .item-group {
        max-width: none;
    }
    .item-group.page,
    .header-wrapper {
        @include home-section-text(
            $width: none,
            $baseline: (
                small: $baseline,
                medium: $baseline * 2
            ),
            $gutter-type: 'inner'
        );
        text-align: left;
    }
    .item-wrapper {
        @include home-section-text(
            $width: none,
            $baseline: (
                small: $baseline,
                medium: $baseline * 2
            ),
            $gutter: 0
        );
        text-align: left;
        margin-bottom: $baseline * 2;
        @include breakpoint(medium up) {
            margin-bottom: $baseline * 3;
        }
    }
    .rte {
        @include typography('home-rte-text');
    }
    .rte a {
        text-decoration: none;
        font-weight: $semibold;
    }
    .item-group.callouts-grid {
        margin-left: 0;
        margin-right: 0;
    }
}

.layout-home-home {
    .item-group.callouts-sections,
    .footer {
        margin-top: 0;
    }
}
.item-group.home-section:first-child {
    @include home-section-background(rgba(0,0,0,0.4));
    @include home-section-white;
    @include rte-content($baseline: $baseline / 2);
    &::before {
        z-index: 3;
    }
    .button {
        @include typography('home-rte-text');
    }
    .page {
        z-index: 4;
        text-align: center;
        @include home-section-text-spacing(
            $width: none,
            $gutter-type: 'inner'
        );
        & > * {
            margin-left: 0;
            margin-right: 0;
        }
        .rte {
            text-align: left;
        }  
    }

    @include breakout($home-section-padding) {
        .link-button {
            margin-top: $value;
        }
    }

    @supports (display: grid) {
        display: grid;
        grid-template-rows: min-content;
        grid-template-columns: auto;
        align-content: center;
        overflow: hidden;
        &::before,
        .page {
            position: relative;
            grid-column: 1;
            grid-row: 1;
        }
        &::before {
            align-self: stretch;
            justify-self: stretch;
            @include breakout($home-section-padding) {
                box-shadow: 0 0 $value $value rgba(0,0,0,0.4);
            }
        }
    }

    @include breakout($header-height) {
        min-height: calc(100vh - #{$value});
    }

}

.item-group.home-section:nth-child(3) {
    background-color: $off-white;
}

.item-group.home-section:nth-child(4) {
    @include home-section-background($secondary-color, '/assets/images/home-section-background.svg');
    @include home-section-white;
    background-color: $secondary-color;
    h2.title {
        color: $primary-color;
    }
    &::before {
        opacity: .5;
        background-blend-mode: multiply;
    }
}

.item-group.callouts-sections {
    .item {
        display: block;
        @include home-section($home-section-padding, $home-section-inner-padding);
    }
    a.item {
        color: inherit;
        &:hover,
        &:focus {
            .button {
                @include button-color('fill', true);
            }
        }
    }
    .item:nth-child(2n + 1) {
        @include home-section-background($secondary-color, '/assets/images/home-section-background.svg');
        background-color: $secondary-color;
        color: $text-color-white;
        &::before {
            opacity: .5;
            background-blend-mode: multiply;
        }
        .button {
            @include button-color('white');
        }
        &:hover,
        &:focus {
            .button {
                @include button-color('white', true);
            }
        }
    }
    .text-group {
        @include home-section-text(
            $width: none,
            $baseline: (
                small: $baseline,
                medium: $baseline * 2
            ),
            $gutter-type: 'inner'
        );
        .title {
            @include typography('home-header-b');
        }
        .text {
            @include typography('home-description-text');
        }
        text-align: left;
    }
}
