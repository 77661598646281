@import 'variables';
@import 'site:icon-mixins';
@import '@depot/breakpoint';
@import '@depot/utility';
@import '@depot/typography';
@import 'mixins';

@include search-form(
    $primary-color: $text-color-regular,
    $secondary-color: $anchor-color-hover
);

@include breakpoint($mobile-breakpoint-up up) {
    @include search-form-collapse(
        $primary-color: $text-color-regular,
        $secondary-color: $anchor-color-hover,
        $height: 36px,
        $icon-size: 32px
    );
}

.search-cont .sayt-cont {
    // height: calc(100vh - #{$baseline * 12.5});
    background-color: $white;
    border: 1px solid $anchor-color;
    border-top: 0;

    .search-result a,
    .sayt-no-results {
        color: $text-color-regular;
    }
    .search-result a:hover,
    .sayt-selected a {
        background-color: rgba($secondary-color, .2);
    }
    @include breakpoint($mobile-breakpoint-up up) {
        height: auto;
        max-height: 60vh;
    }
}

.item-group.search-results {
    @include search-results;
    .paging {
        @include search-paging-buttons;
    }
}
