@import 'parent:';

.page-layout {
    .primary {
        max-width: none;
    }
    .secondary + .primary {
        max-width: none;
    }
    .bottom {
        grid-row: 3;
        grid-column: 1 / span 14;
        max-width: none;
    }
}

.layout-sitemap-page,
.layout-not-found-page {
    .page-layout .primary {
        max-width: none;
    }
}
