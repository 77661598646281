$header-background-color: $white !default;

@include sticky-header-base;

@include breakpoint($mobile-breakpoint-down down) {
    @include header-collapse;
}

.header {
    .logo {
        display: flex;
        @include breakout($header-logo-height) {
            height: $value;
        }
        img {
            transition: opacity .1s;
            height: 100%;
        }
    }
    a.logo:hover,
    a.logo:focus {
        img {
            opacity: .6;
        }
    }
}

//grid
.header-inner {
    @include grid;
    @include breakout($header-offset) {
        padding-top: $value;
    }
    @include breakout($outer-gutters) {    
        padding-left: $value;
        padding-right: $value;
        max-width: calc(#{$site-width} + #{$value * 2});
    }
    margin-left: auto;
    margin-right: auto;
    top: 0;
    width: 100%;
    padding-bottom: $baseline;
    background-color: $header-background-color;
    box-shadow: none;
    @include grid-template-rows(get-breakpoint-value($sticky-header-height, small) auto 1fr auto auto);
    @include grid-template-columns(1fr);
    & > * {
        @include grid-column(1);
        margin-left: $gutter;
        margin-right: $gutter;
    }
    .logo {
        @include grid-row(1);
        @include grid-justify-self(start);
        @include grid-align-self(center);
    }
    .mobile-menu-expand {
        z-index: 2;
        @include grid-row(1);
        @include grid-justify-self(end);
        @include grid-align-self(center);
        margin-right: 0;
    }
    .search-cont,
    .nav-extra,
    .nav-social {
        margin-top: $baseline / 2;
    }
    .search-cont {
        @include grid-row(2);
        z-index: 20;
    }
    .primary-navigation {
        @include grid-row(3);
    }
    .nav-extra {
        @include grid-row(4);
        @include grid-justify-self(center);
    }
    .nav-social {
        @include grid-row(5);
    }
    &.open {
        box-shadow: 0 38px 72px 30px rgba(0,0,0,0.7);
    }
    @include breakpoint($mobile-breakpoint-up up) {
        padding-bottom: 0;
        @include grid-template-rows(auto auto);
        @include grid-template-columns(auto 1fr auto auto);
        .logo {
            @include grid-row(1, 2);
            @include grid-column(1);
            @include grid-justify-self(start);
        }
        .search-cont,
        .primary-navigation,
        .nav-extra,
        .nav-social {
            margin-top: 0;
        }
        .primary-navigation {
            @include grid-row(2);
            @include grid-column(2);
            @include grid-justify-self(end);
            @include grid-align-self(center);
        }
        .nav-extra {
            @include grid-row(1);
            @include grid-column(2, 3);
            @include grid-justify-self(end);
            @include grid-align-self(center);
        }
        .search-cont {
            @include grid-row(2);
            @include grid-column(3);
            @include grid-align-self(center);
        }
        .nav-social {
            @include grid-row(2);
            @include grid-column(4);
            @include grid-align-self(center);
        }
    }
    @include breakpoint(large up) {

    }
}

//navigation
.nav-social {
    @include unstyled-list;
    margin-right: $gutter;
    margin-left: $gutter;
    display: flex;
    & > li {
        margin-left: .75rem;
        &:first-child {
            margin-left: 0;
        }
    }
}
.nav-extra {
    @include unstyled-list;
    margin-right: $gutter;
    margin-left: $gutter;
    display: flex;
    li {
        margin-left: .4rem;
        &:first-child {
            margin-left: 0;
        }
    }
    a {
        display: block;    
    }
}

.primary-navigation {
    font-family: $header-font-family;
    text-transform: uppercase;
    box-shadow: 0 1px 0 0 $light-gray;
    a {
        display: block;
        line-height: 1;
        padding-left: .5rem;
        padding-right: .5rem;
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .is-active > a {
        color: $primary-color;
    }
    .is-active > a:hover,
    .is-active > a:focus {
        color: $anchor-color-hover;
    }
}
.mobile-menu-expand {
    @include menu-button($text-color-regular);
    &.open {
        @include menu-button-open;
    }
    @include breakpoint($mobile-breakpoint-up up) {
        display: none;
    }
}
@include breakpoint($mobile-breakpoint-down down) {
    .primary-navigation {
        @include slide-menu();
        @include slide-menu-icons($height: .75em);
        overflow-x: hidden;
        overflow-y: scroll;
        .nav-primary {
            z-index: 1;
            padding: 0;
        }
        li:not(.icon) a {
            white-space: normal;
            &::before {
                margin-left: .375em;
                margin-right: .875em;
            }
            &::after {
                margin-left: auto;
                margin-right: .875em;
            }
        }
        .nav-home-title {
            margin-top: $baseline / 2;
            a {
                flex-grow: 1;
            }
        }
        .nav-list,
        .sub-menu {
            &::before {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 48px;
                right: 48px;
                height: 1px;
                background: $white;
            }
        }
        a {
           padding-top: $baseline;
           padding-bottom: $baseline;
        }
    }
    .header-inner .nav-social {
        justify-content: center;
        // padding: $baseline / 2;
    }
}
@include breakpoint($mobile-breakpoint-up up) {
    .primary-navigation {
        box-shadow: none;
        .nav-primary {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
        .nav-home-title,
        .nav-section-title,
        .sub-menu {
            display: none;
        }
    }
}
