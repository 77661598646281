@mixin callout-basic-item(
    $image-size: (
        small: $baseline * 4,
        smallplus: $baseline * 6,
        medium: $baseline * 9
    ),
    $baseline: (
        small: $baseline,
        medium: $baseline * 2
    ),
    $compact-breakpoint: smallplus,
    $constrain-image-width: true
) {
    a.item {
        color: inherit;
    }
    .item {
        display: flex;
        justify-content: center;
    }
    .head {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        max-width: 380px;
        .image {
            display: flex;
            flex-shrink: 0;
            svg {
                width: 100%;
                height: 100%;
            }
            width: 100%;
            @include breakout($image-size) {
                height: $value;
                @if $constrain-image-width {
                  width: $value;
                }
            }
        }
        .text-group {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            width: 100%;
            & > * {
                margin-bottom: 0;
                @include breakout($baseline) {
                    margin-top: $value;
                }
            }
        }
        .summary {
            flex-grow: 1;
        }
    }
    .no-image .text-group > *:first-child {
        margin-top: 0;
    }
    a.item:hover {
        .button {
            @extend .button:hover !optional;
        }
    }
    @if $compact-breakpoint != false {
        .head {
            @include breakpoint($compact-breakpoint down) {
                position: relative;
                @include grid;
                @include grid-template-columns(max-content 1fr);
                & > * {
                }
                .image {
                    @include grid-row(1);
                    @include grid-column(1);
                    @include grid-align-self(start);
                    margin-right: $gutter * 2;
                }
                .text-group {
                    @include grid-row(1);
                    @include grid-column(2);
                    @include grid-align-self(center);
                    text-align: left;
                }
                .title {
                    margin-top: 0;
                }
                .button-cont {
                    text-align: left;
                }

            }
            @include breakpoint(small down) {
                .image {
                    margin-right: $gutter * 1.5;
                }
            }
        }
    }
}

@mixin callouts-3() {
    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .item {
        margin-top: $baseline * 3;
        @include flex-column(12);
        @include breakpoint(smallplus down) {
            &:first-child {
                margin-top: 0;
            }
        }
        @include breakpoint(medium up) {
            @include flex-column(6, $gutter * 3)
            margin-top: $baseline * 2;
            margin-bottom: $baseline * 2;
        }
        @include breakpoint(large up) {
            @include flex-column(4, $gutter * 4)
        }
    }
}

@mixin callout-grid-item(
  $height: 100%
) {
   a.item {
       img {
           transition: transform .3s ease;
       }
   }
   a.item:hover,
   a.item:focus {
       img {
           transform: scale(1.1);
       }
   }
       .head {
           position: relative;
           width: 100%;
           &::before {
               content: '';
               display: block;
               width: 100%;
               padding-bottom: $height;
           }
           .image {
               display: flex;
               position: absolute;
               top: 0;
               left: 0;
               right: 0;
               bottom: 0;
               @include object-fit;
               overflow: hidden;
           }
           .text-group {
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               padding: $baseline / 2 $gutter / 2;
               background-color: rgba($black, 0.5);
               color: $white;
           }
           .title {
               font-size: #{14 / $em}rem;
               line-height: 1.2;
               @include breakpoint(large up) {
                   font-size: #{18 / $em}rem;
               }
           }
       }
}

@mixin callouts-grid(
    $gutter: $gutter / 2,
    $per-row: (
        small: 2,
        smallplus: 3,
        medium: 4,
        xlarge: 6,
    )
) {
    .items {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;
        margin-right: -$gutter;
    }
    .item {
        margin-bottom: $gutter * 2;

        @include breakout($per-row) {
            @include flex-column(1, $gutter, $value);
        }
    }
}
