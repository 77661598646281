@mixin home-section-text(
    $width: $content-width,
    $gutter: $gutter,
    $baseline: (
        small: $baseline * 2,
        medium: $baseline * 3
    ),
    $gutter-type: 'outer'
) {
    text-align: center;
    @include home-section-text-spacing($width, $gutter, $baseline, $gutter-type);
    .summary {
        @include typography('home-summary-text');
    }
    .description {
        @include typography('home-description-text');
    }
    .text {
        @include typography('home-rte-text');
    }
}
@mixin home-section-text-spacing(
    $width: $content-width,
    $gutter: $gutter,
    $baseline: (
        small: $baseline * 2,
        medium: $baseline * 3
    ),
    $gutter-type: 'outer'
) {
    & > * {
        @include breakout($baseline) {
            margin-top: $value;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    @if $gutter-type == 'outer' {
        max-width: $width;
        margin-left: auto;
        margin-right: auto;
        @include breakout($gutter) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    } @else {
        max-width: none;
        & > * {
            max-width: $width;
            margin-left: auto;
            margin-right: auto;
            @include breakout($gutter) {
                padding-left: $gutter;
                padding-right: $gutter;
            }
        }
    }
}
@mixin home-section-background($background-color, $image-url: false) {
    &::before {
        background-color: $background-color;
        @if $image-url {
            background-image: url(#{$image-url});
        }
    }
}
@mixin home-section($section-padding, $inner-padding: $section-padding) {
    @include home-section-vertical-spacing($section-padding, $inner-padding);
    position: relative;
    margin: 0;
    .slideshow,
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &::before {
        content: '';
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    & > * {
        z-index: 2;
        position: relative;
    }
    @include breakout($outer-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
    h1.title {
        @include typography('home-header-a');
    }
    h2.title {
        @include typography('home-header-b');
    }
    & > .item-group,
    & > .sb-display-point-first {
        max-width: $site-width;
        margin-left: auto;
        margin-right: auto;
    }
    & > .slideshow + .item-group {
        margin-top: 0;
    }
    .sb-display-point-first.slideshow > a {
        padding: $baseline;
        align-items: flex-start;
    }
    .item-group.slideshow {
        max-width: none;
        .sb-display-point-edit {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .slideshow-cont,
        .slideshow-stage {
            position: static;
        }
        .item,
        .image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .image {
            @include object-fit;
            display: flex;
        }
    }
    .item-group.callouts-grid {
        max-width: none;
        padding-left: $gutter;
        padding-right: $gutter;
        @include breakout($outer-gutters) {
            margin-left: -$value;
            margin-right: -$value;
        }
    }
}
@mixin home-section-vertical-spacing($section-padding, $inner-padding: $section-padding) {
    @include breakout($section-padding) {
        padding-top: $value;
        padding-bottom: $value;
    }
    & > .item-group,
    & > .sb-display-point-first {
        @include breakout($inner-padding) {
            margin-top: $value;
        }
        &:first-child {
            margin-top: 0;
        }
        .header-wrapper {
            @include breakout($inner-padding) {
                margin-bottom: $value;
            }
        }
    }
    & > .slideshow + .item-group {
        margin-top: 0;
    }
}