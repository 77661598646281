@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/utility';
@import 'site:variables';
@import 'forms:mixins';
@import 'callouts:mixins';

.item-group.nav-overview {
    // @include overview-grid;
    @include callouts-grid(
        $per-row: (
            small: 2,
            // smallplus: 3,
            medium: 3,
        )
    );
    @include callout-grid-item($height: 25%);
    @include callout-grid-item-plus(
        $background-color: $gray,
        $background-color-hover: $primary-color,
        $text-background-color: transparent    
    );
    .item {
        border-radius: $baseline * 6;
        overflow: hidden;
    }
    .item,
    .head {
        display: flex;
    }
    .head {
        &::before {
            display: none;
        }
        .text-group {
            position: relative;
            width: 100%;
            min-height: $baseline * 6;
        }
    }
}
