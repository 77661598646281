@import 'site:variables';

@import '@depot/breakpoint';

@import 'news:mixins';
.layout-news-page,
.layout-articles-page {
    .primary .group.page {
        @include news-page-text;
    }
}
