@import 'variables';
@import 'mixins';

.item-group.article-list {
    @include article-list;
}
.item-group.article-image-icon {
    .image {
        @include object-fit((object-fit: contain));
        img {
            border-radius: 0;
        }
    }
}
