@import 'parent:';

@mixin callouts-alternating {
    .head {
        margin-top: $baseline * 3;
        @include breakpoint(medium up) {
            @include grid;
            @include grid-template-rows(min-content);
            @include grid-template-columns(1fr 1fr);
            margin-left: -$gutter;
            margin-right: -$gutter;
        }
    }
    .item:first-child .head {
        margin-top: 0;
    }
    .image,
    .text-group {
        @include grid-row(1);
        @include breakpoint(medium up) {
            margin-left: $gutter;
            margin-right: $gutter;
        }
    }
    .image {
        @include grid-align-self(stretch);
        @include grid-column(2);
        @include object-fit;
        position: relative;
        min-height: $baseline * 15;
        margin-bottom: $baseline * 1.5;
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .image-caption {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $baseline / 3 1rem;
            background-color: rgba(0,0,0,0.7);
            color: $text-color-white;
        }
        @include breakpoint(medium up) {
            margin-bottom: 0;
            img {
                height: auto;
                min-height: $baseline * 15;
                max-height: 100%;
            }
        }
        @include breakpoint(large up) {
            min-height: #{320 / $em}rem;
            img {
                min-height: #{320 / $em}rem;
            }
        }
    }
    .text-group {
        @include grid-column(1);
        @include grid-align-self(center);
        & > * {
            margin-top: $baseline;
            &:first-child {
                margin-top: 0;
            }
        }
        @include breakpoint(medium up) {
        }
    }
    .item:nth-child(even) {
        .image {
            @include grid-column(1);
        }
        .text-group {
            @include grid-column(2);
        }
    }
    .item .no-image {
        .text-group {
            @include grid-column(1, 2);
        }
    }
    a.item {
        display: block;
        position: relative;
        background-color: $secondary-color;
        color: $text-color-white;
        margin-top: $baseline * 2;
        margin-left: $gutter * -2;
        margin-right: $gutter * -2;
        padding: $baseline * 2 $gutter * 2;
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $secondary-color;
            background-image: url('/assets/images/home-section-background.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-blend-mode: multiply;
            pointer-events: none;
            opacity: .5;
        }
        .head {
            position: relative;
            margin-top: 0;
        }
        .button-cont {
            margin-top: $baseline * 2;;
        }
        .button {
            @include button-color('white');
        }
        &:hover,
        &:focus {
            .button {
                @include button-color('white', true);
            }
        }
    }
}

@mixin callout-grid-item-plus(
    $background-color: $white,
    $background-color-hover: $white,
    $text-background-color: rgba(0,0,0,0.3),
    $text-background-color-hover: $primary-color
) {
    .head {
        .text-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            padding: $baseline $gutter;
            text-align: center;
            background-color: $text-background-color;
            transition: background-color .3s ease;
            & > * {
                margin-top: $baseline / 2;
                max-width: 100%;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .image + .text-group {
            top: auto;
        }
    }
    .item {
        background-color: $background-color;
        transition: background-color .3s ease;
        .image {
            img {
                // mix-blend-mode: multiply;
                // transition: none;
            }
        }
    }
    .head::before {
        // z-index: 2;
        // position: relative;
    }
    .head .text-group {
        // z-index: 3;
    }
    a.item {
        .head::before {
            // background-color: rgba($background-color, 0.7);
            // transition: background-color .3s ease;
        }
    }
    a.item:hover,
    a.item:focus {
        background-color: $background-color-hover;
        .image img {
            transform: none;
        }
        .head::before {
            // background-color: rgba($background-color-hover, 0.7);
        }
        .text-group {
            background-color: $text-background-color-hover;
        }
    }
    // @supports (mix-blend-mode: multiply) {
    //     a.item {
    //         .head::before {
    //             position: static;
    //             z-index: 1;
    //             background-color: transparent;
    //         }
    //     }
    //     a.item:hover,
    //     a.item:focus {
    //         .head::before {
    //             background-color: transparent;
    //         }
    //     }
    // }
}
