.footer {
    // @include typography('text-large');
    @include icons($footer-color, $anchor-color);
    @include breakout($outer-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
    background-color: $footer-bg;
    box-shadow: 0 -1px 0 0 $light-gray;
    margin-top: $baseline * 3;
    color: $footer-color;
}

.content:has(.bottom) ~ .footer {
    margin-top: 0;
}

.footer-logo {
    display: block;
    img {
        height: $baseline * 4;   
    }
}

.footer-blurb {
    p {
        margin: $baseline * 1.5 0 0;
    }
    p:first-child {
        margin-top: 0;
    }
    @include breakpoint(large up) {
        p:first-child {
            margin-top: $baseline / 2;
        }
    }
}

.footer-contact {
    .contact-header {
        @include typography('header-d');
        margin-right: .5em;
        @include breakpoint(small only) {
            display: block;
        }
    }
    .nav-social {
        margin: $baseline / 2 0 0;
    }
    .contact-list {
        margin-top: $baseline;
    }
}

.footer-navigation {
    .nav-list {
        margin-top: $baseline / 2;
        li {
            display: inline-block;
            margin-right: 1em;
        }
        a {
            display: block;
            &::before {
                content: '>';
                margin-right: .5em;
            }
        } 
    }
}

.footer-inner {
    max-width: $site-width;
    margin-left: auto;
    margin-right: auto;
    padding-top: $baseline * 2;
    padding-bottom: $baseline * 4;
    // text-align: center;
    a {
        color: $footer-color;
        &:hover,
        &:focus {
            color: $anchor-color;
        }
    }
    & > * {
        display: block;
        margin-top: $baseline * 2;
        margin-left: auto;
        margin-right: auto;
        max-width: 380px + $gutter * 2;
        padding-left: $gutter;
        padding-right: $gutter;
    }
    @include breakpoint(medium up) {
        @include grid;
        @include grid-template-columns(1fr 1fr);
        & > * {
            @include grid-justify-self(center);
            margin-left: 0;
            margin-right: 0;
        }
        .footer-blurb {
            @include grid-row(1);
            @include grid-column(1);
            width: 100%;
        }
        .footer-contact {
            @include grid-row(1);
            @include grid-column(2);
            width: 100%;
            .contact-header {
                display: block;
            }
        }
        .footer-navigation {
            @include grid-row(2);
            @include grid-column(1, 2);
            max-width: none;
        }
    }
    @include breakpoint(800px up) {
        .footer-contact .contact-header {
            display: inline-block;
        }
    }
    @include breakpoint(1200px up) {
        @include grid-template-columns(auto 1fr auto);
        & > * {
            // @include grid-justify-self(start);
        }
        .footer-blurb {

        }
        .footer-contact {

        }
        .footer-navigation {
            @include grid-row(1);
            @include grid-column(3, 1);
            .nav-list {
                column-count: 2;
                column-gap: 0;
            }
            .nav-list li {
                display: block;
            }
        }
    }
}
