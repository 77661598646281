@import 'parent:';

@mixin banner-slideshow-static ($height) {
    margin-top: 0;
    margin-left: -$gutter;
    margin-right: -$gutter;
    .image {
        @include object-fit;
        display: flex;
        @include breakout($height) {
            height: $value;
        }
    }
}
@mixin banner-slideshow-fixed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: none;
    display: flex;
    .sb-display-point-edit,
    .sb-display-point-edit-content,
    .slideshow-cont,
    .slideshow-stage,
    .items {
        display: flex;
        flex-grow: 1;
    }
    .item {
        position: relative;
    }
    .image {
        @include object-fit;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
    }
}
