@import 'site:variables';
@import '@foundation/util/util';
@import '@foundation/components/visibility';
@import '@depot/utility';
@import 'normalize';

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 668px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
) !default;
$breakpoint-classes: (small smallplus medium large xlarge xxlarge) !default;

@include normalize;
@include foundation-visibility-classes;

//applied to a <meta> tag, to be used in js
.foundation-mq {
    font-family: '#{-zf-bp-serialize($breakpoints)}';
}

html {
    box-sizing: border-box;
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
    box-sizing: inherit;
}

// Default body styles
body {
    padding: 0;
    margin: 0;
}

figure {
    margin: 0;
}

a {
    text-decoration: none;
}

img {
    // Grid defaults to get images and embeds to work properly
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;
}

// Make select elements are 100% width by default
select {
    width: 100%;
}

// Styles Google Maps and MapQuest embeds properly
// scss-lint:disable IdSelector
#map_canvas,
.map_canvas,
.mqa-display {
    img,
    embed,
    object {
        max-width: none !important;
    }
}

// Reset <button> styles created by most browsers
button {
    // @include disable-mouse-outline;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 1;
}

// Internal classes to show/hide elements in JavaScript
.is-visible {
    display: block !important;
}
.is-hidden {
    display: none !important;
}

.unstyled-list {
    @include unstyled-list;
}
