$baseline: .75rem !default;

$mobile-breakpoint-up: medium !default;
$sticky-header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: 0,
) !default;
$header-height: (
    small: $baseline * 5,
    $mobile-breakpoint-up: $baseline * 8,
) !default;
$header-navigation-min-height: 400px !default;
$header-navigation-reserved-height: 72px !default;
$header-height-breakpoint: $header-navigation-min-height + $header-navigation-reserved-height;

@mixin sticky-header-base {
    .header {
        z-index: 20;
        position: relative;
        top: 0;
        @include breakout($header-height) {
            min-height: $value;
        }
        @supports ((position: -webkit-sticky) or (position: sticky)) {
            @media screen and (min-height: $header-height-breakpoint) {  
                @include breakout($sticky-header-height) {
                    @if $value != 0 {
                        position: -webkit-sticky;
                        position: sticky;
                        top: (get-breakpoint-value($header-height, $breakpoint) - $value) * -1;
                    } @else {
                        position: relative;
                        top: 0;
                    }
                }
            }
        }
    }
    .header-inner {
        min-height: 0;
        @include breakout($header-height) {
            height: $value;
        }
    }
}

@mixin header-collapse(
    $header-transition: all .4s ease,
    $header-transition-open: $header-transition,
    $header-item-transition: opacity .2s,
    $header-item-transition-open: opacity .2s .3s
) {
    .header-inner {
        @media screen and (min-height: $header-height-breakpoint) {
            position: absolute;
            top: 0;
            left: 0;
        }
        overflow: hidden;
        transition: #{$header-transition};
        %header-collapse-navigation-item,
        .search-cont,
        .primary-navigation,
        .nav-social,
        .nav-extra {
            opacity: 0;
            transition: #{$header-item-transition};
        }
        &.open {
            transition: #{$header-transition-open};
            min-height: $header-navigation-min-height;
            %header-collapse-navigation-item,
            .search-cont,
            .primary-navigation,
            .nav-social,
            .nav-extra {
                opacity: 1;
                transition: #{$header-item-transition-open};   
            }
        }
    }
    @include breakout($sticky-header-height) {
        @media screen and (min-height: $header-height-breakpoint) {
            $offset: get-breakpoint-value($header-height, $breakpoint) - $value;
            .header-inner.open {
                @if $offset != 0 {
                    height: calc(100vh + #{$offset} - #{$header-navigation-reserved-height});
                } @else {
                    height: calc(100vh - #{$header-navigation-reserved-height});
                }
            }
            .header:not(.is-stuck) .header-inner.open {
                margin-top: -#{$offset};
            }
        }
    }
}

@mixin menu-button($color: #fff) {
    position: relative;
    width: 24px;
    height: 3px;
    background-color: $color;
    text-indent: 200%;  
    overflow: hidden;
    padding: 23px 12px 22px;
    box-sizing: content-box;
    background-clip: content-box;
    transition: background .3s ease;
    &::after, &::before {
        display: block;
        left: 12px;
        content: '';
        height: 3px;
        position: absolute;
        width: 24px;
        background-color: $color;
        transform-origin: center;
        transition: background .3s ease,
                    transform .3s ease,
                    top .3s ease,
                    bottom .3s ease;
    }
    &::before {
        top: 16px;
    }
    &::after {
        bottom: 15px;
    }
}
@mixin menu-button-open {
    background-color: transparent;
    &::before, &::after {
        // background-color: $primary-color;
        // left: 12px;
    }
    &::before {
        top: 23px;
        transform: rotate(45deg);
    }
    &::after {
        bottom: 22px;
        transform: rotate(-45deg);
    }
}
@mixin slide-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    .nav-primary,
    .nav-home-title {
        transition: transform .3s ease;
    }
    .nav-home-title {
        order: 1;
    }
    .nav-primary {
        order: 2;
        position: relative;
    }
    .social {
        order: 3;
    }
    .nav-home-title,
    .nav-section-title {
        display: flex;
        visibility: hidden;
        flex-shrink: 0;
    }
    .nav-home-title {
        visibility: visible;
        transform: translateX(-100%);
    }
    .current + .nav-home-title {
        transform: translateX(0%);
    }
    .nav-primary,
    .sub-menu {
        width: 100%;
    }
    .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        display: none;
    }
    .nav-section-title {
        position: absolute;
        bottom: 100%;
        left: 100%;
        right: -100%;
    }
    .sub-menu.current,
    .sub-menu.previous,
    .sub-menu.contains-current {
        display: block;
    }
    .sub-menu.current + .nav-section-title,
    .sub-menu.previous + .nav-section-title {
        visibility: visible;
    }
}
@mixin slide-menu-icons($height: 12px, $x-space: $baseline / 2, $y-space: $baseline / 2) {
    li:not(.icon) > a {
        display: flex;
        &::before,
        &::after {
            content: '';
            display: block;
            box-sizing: border-box;
            align-self: center;
            width: $height;
            height: $height;
            border-color: inherit;
            flex-shrink: 0;
        }
        &::before {
            margin-right: $x-space;
        }
        &::after {
            margin-left: $x-space;
        }
    }
    .has-sub-menu > a:not(.nav-section-title)::after {
        border-top: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
    }
    .nav-section-title {
        &::before {
            border-top: 2px solid;
            border-right: 2px solid;
            transform: rotate(225deg);
        }
    }
    .is-active {
        &::before {
            background-color: white;
            border-radius: 100%;
        }
    }
}
@mixin flyout-menu-base() {
    .has-sub-menu {
        position: relative;
    }
    .sub-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
        a {
            white-space: nowrap;
        }
    }
}
@mixin flyout-menu-full($secondary-menu-color: darken($primary-color, 10%), $tertiary-menu-color: darken($primary-color, 20%)) {
    $super-easeout: cubic-bezier(0,0,0.2,1);
    $super-easein: cubic-bezier(.8,0,1,1);
    @include flyout-menu-base();
    .nav-list {
        display: flex;
        justify-content: space-between;
        & > li {
            flex-grow: 1;
        }
        a:not(.nav-section-title) {
            display: block;
            position: relative;
            overflow: hidden;
            z-index: 0;
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
        & > .has-sub-menu {
            & > a::before {
                background-color: $secondary-menu-color;
                transform: translateY(-100%);
            }
            & > a:hover::before,
            &.menu-open > a::before {
                transform: translateY(0);
            }
        }
    }
    .sub-menu {
        background-color: $secondary-menu-color;
        & > .has-sub-menu {
            & > a::before {
                background-color: $tertiary-menu-color;
                transform: translateX(-100%);
            }
            & > a:hover::before,
            &.menu-open > a::before {
                transform: translateX(0);
            }
        }
        .sub-menu {
            background-color: $tertiary-menu-color;             
        }
    }
    .nav-list {
        a::before {
            transition: transform .25s $super-easein;
        }
        a:not(:hover)::before {
            transition: transform .25s .2s $super-easeout;
        }
    } 
}
@mixin sitemap-basic($baseline: $baseline, $gutter: 1rem, $list-padding: .5rem) {

    a {
        display: block;
    }
    .nav-list {
        // max-width: $content-width;
        // margin: auto;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        // margin-top: $baseline * -1;
        & > li {
            flex-grow: 1;
            flex-basis: auto;
            width: auto;
            margin: 0 $gutter * 2 $baseline * 2 0;
            & > a {
                // @extend %header-b !optional;
                padding: 0 $list-padding / 2 calc(#{$baseline / 2} - 1px) $list-padding / 2;
                border-bottom: 1px solid;
            }
        }
        & > li.has-sub-menu {
            & > a {
                // margin-bottom: $baseline / 2;
            }
        }
    }
    .sub-menu {
        margin-left: $list-padding / 2;
        margin-top: $baseline;
        li {
            margin-top: $baseline / 2;
        }
        .sub-menu {
            margin-top: 0;
            margin-left: $list-padding;
            a {
                line-height: 1.3;
            }
            li{
                margin-top: $baseline / 4;
            }
        }
    }
}
@mixin side-nav-basic (
        $side-nav-shadow-color: $secondary-color,
        $side-nav-color: inherit,
        $side-nav-color-hover: $secondary-color,
        $gutter: $gutter,
        $baseline: $baseline
    ){

    list-style: none;
    padding: 0;
    // margin: 2 * $baseline 0;

    li {
        
        // padding: $baseline * .25 0;
        // margin-bottom: $baseline / 2;
        margin-top: $baseline / 2;
    }
    a {
        display: inline-block;
        padding-top: $baseline * .75;
        padding-bottom: $baseline * .75;
        line-height: $baseline * 1.5;
        color:$side-nav-color;
        @include breakout($gutter) {
            padding-left: $value;
        }
    }
    .is-active > a,
    a:hover,
    a:focus { 
        color: $side-nav-color-hover;
    }
    .is-active > a {
         box-shadow: -1px 0 0 $side-nav-shadow-color;
    }
    .has-sub-menu ul {
        @include breakout($gutter) {
            margin-left: $value;
        }
    }

    .nav-tertiary {
        li {
            line-height: 1.35;
        }

        a {
            font-size: 0.875em;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.3; 
        }

    }
}
@mixin pagination {
    display: flex;
    justify-content: center;
    .pagination-button-list {
        @include unstyled-list;
        display: flex;
        max-width: 100%;
        // justify-content: space-between;
        &::before {
            content: '';
            order: 2;
            display: block;
            width: 4rem;
            flex-shrink: 1;
        }
        & > * {
            flex-shrink: 0;
        }
    }
    a {
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .back {
        order: 1;
        a::before {
            content: '↵';
            display: inline-block;
            transform: rotate(90deg);
            margin-right: .5rem;
        }
    }
    .previous {
        // margin-right: 3 * $baseline;
        order: 3;
        padding-right: .5rem;
        margin-right: .5rem;
        border-right: 1px solid $text-color-regular;
        a::before {
            content: '❬';
            display: inline;
            margin-right: .5rem;
        }
    }
    .next {
        order: 4;
        a::after {
            content: '❭';
            display: inline;
            margin-left: .5rem;
        }
    }
    li.disabled {
        color: rgba($text-color-regular, .6);
    }
}