@import 'site:variables';
@import '@depot/utility';
@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/typography';
@import 'forms:mixins';
@import 'articles:variables';
@import 'articles:mixins';
@import 'mixins';

.header-banner {
    @include product-banner;
}

.layout-products-page,
.layout-products-list {
    @include page-layout-product;
}
.layout-products-list:not(.no-products) {
    @include page-layout-product-list;
}
.layout-products-list.no-products {
    /* .secondary { */
    /*     display: none; */
    /* } */
    /* @include breakpoint($mobile-breakpoint-up up) { */
    /*     .secondary + .primary { */
    /*         @include grid-column(1, 12, true); */
    /*     } */
    /* } */
}
.lister.products-list {
    @include products-list;
}
