$product-header-section-height: (
    small: #{180/$em}rem,
    medium: #{240/$em}rem,
    large: #{448/$em}rem
) !default;
$product-header-section-padding: (
    small: $baseline * 2,
    medium: $baseline * 3,
    large: $baseline * 4
) !default;

@mixin page-layout-product {
    .primary {
        max-width: none;
        & > .page {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
@mixin page-layout-product-list {
    .primary {
        @include breakpoint(large up) {
            .item-group,
            .sb-display-point-first {
                margin-top: $baseline * 3;
            }
        }
    }
}
@mixin products-list {
    @include article-icons(
        $text-color-regular,
        $anchor-color-hover,
        $size: (
            small: 1rem,
            medium: $baseline * 2
        )
    );
    @include basic-article-list(
        $image-size: (
            small: $baseline * 2.5,
            smallplus: $baseline * 3.5
        ),
        $baseline: $baseline / 2,
        $gutter: $baseline,
    );
    .head {
        align-items: center;
        img {
            border-radius: 50%;
        }
    }
    .item-wrapper {
        margin-bottom: 0;
    }
    h2.title {
        @include typography('header-c');
    }
    .head .title {
        @include typography('header-e');
    }
}
@mixin product-banner {
    @include breakout($product-header-section-padding) {
        padding-top: $value;
        padding-bottom: $value;
    }
    @include breakout($product-header-section-height) {
        min-height: $value;
    }
    a {
        color: $primary-color-light;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $secondary-color;
        background-image: url('/assets/images/home-section-background.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-blend-mode: multiply;
        pointer-events: none;
        opacity: .5;
    }
    .page {
        @include grid;
        @include grid14;
        position: relative;
        width: auto;
        max-width: none;
        margin: 0 -#{$gutter};
        pointer-events: none;
        & > * {
            @include grid-column(1, 12, true);
            position: relative;
            padding-left: $gutter;
            padding-right: $gutter;

            pointer-events: auto;
        }
        @include make-grid-rows('breadcrumbs' '.title' '.subtitle' '.image' '.description' '.text' '.brochure-link');
        .breadcrumbs {
            margin-top: 0;
            a {
                color: $text-color-white;
            }
        }
        .image {
            margin-top: $baseline;
            text-align: center;
            img {
                max-height: #{320/$em}rem;
            }
        }
        .subtitle {
            @include typography('header-b');
        }
        .brochure-link {
            margin-top: $baseline * 2;
        }
        .download-link {
            @include button;
            @include button-color('white');
            text-decoration: none;
        }
        @include breakpoint(large up) {
            @supports (display: grid) {
                grid-auto-rows: min-content;
                //needed to fix safari v10 bug
                align-content: start;
                .title,
                .subtitle,
                .description,
                .text,
                .brochure-link {
                    grid-row: auto;
                }

                .image ~ * {
                    width: 100%;
                    text-align: left;
                    grid-column: 2 / span 7;
                    margin-left: 0;
                    margin-right: 0;

                }
                .image ~ .breadcrumbs {
                    max-width: none;
                }
                .breadcrumbs {
                    margin-top: -#{$baseline};
                }
                .image {
                    grid-column: 9 / span 5;
                    grid-row: 1 / span 7;
                    align-self: center;
                    margin-top: 0;
                    img {
                        // max-height: none;
                    }
                }
            }
        }
        @include breakpoint($site-width up) {
            & > * {
                width: 100%;
                text-align: left;
            }
        }
    }
    .slideshow ~ .page {
        position: relative;
        &::before {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            display: block;
            max-width: $content-width;
            margin: auto;
            background-color: rgba(0,0,0,0.4);
            box-shadow: 0 0 6rem 6rem rgba(0,0,0,0.4);
            @include breakpoint($secondary-breakpoint-up up) {
                max-width: $site-width * 6/12;
            }
        }
    }
    .breadcrumbs ~ .page {
        margin-top: $baseline * 2;
    }
}
