$available-icons: facebook instagram twitter linkedin home !default;
@mixin icon-base($size: 24px, $baseline-offset: false, $background-size: $size) {
    .icon {
        display: inline-flex;
        align-items: center;
    }
    .icon a{
        position: relative;
        display: flex;
        align-items: center;
        &::before,
        &::after {
            content: '';
            display: inline-block;
            flex-shrink: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        &::after {
            order: -1;
            opacity: 0;
        }
        
    }
    .icon a:focus,
    .icon a:hover,
    .icon.is-active a{
        &::before { opacity: 0; }
        &::after { opacity: 1; }
    }
    .icon.hide-text a {
        text-indent: -100%;
        white-space: nowrap;
        overflow: hidden;
        color: transparent !important;
    }
    @include icon-size($size, $baseline-offset, $background-size);
}
@mixin icon-size($size: 24px, $baseline-offset: false, $background-size: $size) {
    .icon a {
        &::before,
        &::after {
            @if variables-exists(background-size) {
                background-size: $background-size $background-size;
            }
            width: $size;
            height: $size;
            @if $baseline-offset {            
                margin-top: calc((#{$size} / -2) - #{$baseline-offset});
                margin-bottom: calc((#{$size} / -2) + #{$baseline-offset});
            } @else {
                margin-top: calc((#{$size} / -2));
                margin-bottom: calc((#{$size} / -2));
            }
        }
        &::after {
            margin-right: -#{$size};
        }
    }
    .icon:not(.hide-text) a{
        //align text to the edge of the background image, not the full icon size
        &::before {
            margin-right: calc((#{$background-size} - #{$size}) / 2 + .5em);
        }
    }
    .icon.hide-text a {
        width: $size;
        &::before,
        &::after {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
@mixin icons(
    $color: null, $hover: null,
    $icons: $available-icons
) {
    @if index($icons, facebook) {
        .icon-facebook a::before {
            @include sb-icon-facebook($color);
        }
        .icon-facebook a::after {
            @include sb-icon-facebook($hover);
        }
    }
    @if index($icons, instagram) {
        .icon-instagram a::before {
            @include sb-icon-instagram($color);
        }
        .icon-instagram a::after{
            @include sb-icon-instagram($hover);
        }
    }
    @if index($icons, twitter) {
        .icon-twitter a::before{
            @include sb-icon-twitter($color);
        }
        .icon-twitter a::after {
            @include sb-icon-twitter($hover);
        }
    }
    @if index($icons, linkedin) {
        .icon-linkedin a::before{
            @include sb-icon-linkedin($color);
        }
        .icon-linkedin a::after {
            @include sb-icon-linkedin($hover);
        }
    }
    @if index($icons, home) {
        .icon-home a::before {
            @include sb-icon-home($color);
        }
        .icon-home a::after {
            @include sb-icon-home($hover);
        }
    }
    @if index($icons, translate) {
        .icon-translate a::before {
            @include sb-icon-translate($color);
        }
        .icon-translate a::after {
            @include sb-icon-translate($hover);
        }
    }
    @if index($icons, youtube) {
        .icon-youtube a::before {
            @include sb-icon-youtube($color);
        }
        .icon-youtube a::after {
            @include sb-icon-youtube($hover);
        }
    }
}
