@import 'parent:';

@mixin article-list {
    @include article-icons(
        $text-color-regular,
        $anchor-color-hover,
        $size: (
            small: 1rem,
            medium: $baseline * 2
        )
    );
    @include basic-article-list(
        $image-size: $article-image-size,
        $gutter: (
            small: $baseline,
            medium: $baseline * 2
        )
    );
    .item {
        border-bottom: 1px solid $text-color-regular;
    }
    .head::after {
        margin-top: 1rem - $baseline;
        @include breakpoint(medium up) {
            margin-top: 0;
        }
    }
    .head img {
        border-radius: 50%;
    }
    .show {
        .button {
            margin-top: $baseline;
            margin-right: $baseline;
        }
    }
    .item.is-active {
        display: none;
    }
    .file-statistics {
        font-size: .7em;
        font-weight: $normal;
    }
    @include breakpoint(small only) {
        .head {
            display: block;
            position: relative;
            .image {
                float: left;
                margin-right: $gutter;
            }
            .text-group {
                padding: 0;
            }
            .text-group .title {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-right: calc(1rem + #{$gutter});
                & > * {
                    flex-basis: 100%;
                }
            }
            .image + .text-group .title {
                min-height: map-get($article-image-size, small);
            }
            &::after {
                position: absolute;
                top: $baseline * 1.5;
                right: 0;
            }
        }
        .has-images .expander-bodyu {
            margin-left: 0;
        }
        .expander-bodyu > * {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
