$banner-background-color: $primary-color !default;
$banner-text-color: $text-color-white !default;
.site {
    display: flex;
    flex-direction: column;
}
.content {
    flex-grow: 1;
}
.page-layout {
    @include page-layout-base;
}
.header-banner {
    position: relative;
    background-color: $banner-background-color;
    color: $banner-text-color;
}
.layout-home-home {
    .page-layout {
        @include page-layout-home;
    }
}
