@import '@depot/breakpoint';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/utility';
@import 'site:variables';
@import 'forms:mixins';
@import 'mixins';


.item-group.callouts-3,
.item-group.callouts-2,
.item-group.callouts-4,
.item-group.callouts-icons {
    .item {
        svg {
            fill: $anchor-color;
            transition: fill .3s;
        }
    }
    a.item:hover,
    a.item:focus {
        svg {
            fill: $anchor-color-hover;
        }
        .button {
            @include button-color('base', true);
        }
    }
    .head .image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.item-group.callouts-2 {
    @include callout-basic-item;
    .items {    
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2
            ),
            $baseline: $baseline * 1.5,
            $shrink-wrap: true
        );
        padding-left: $gutter;
        padding-right: $gutter;
    }
    .head {
        text-align: left;
        margin-left: 15%;
        .text-group .button-cont {
            margin-top: $baseline * 2;
        }
    }
}
.item-group.callouts-3 {
    // @include callouts-3;
    @include callout-basic-item(
        $image-size: (
            small: $baseline * 4,
            smallplus: $baseline * 6,
            medium: $baseline * 10
        ),
        $baseline: (
            small: $baseline * 2,
            // large: $baseline * 4
        ),
        $compact-breakpoint: false,
        $constrain-image-width: false
    );
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                480px: 2,
                large: 3
            ),
            $baseline: (
                small: $baseline * 1.5,
                480px: $baseline * 2
            ),
            $shrink-wrap: true
        );
        justify-content: center;
    }
    a.item {
        color: $anchor-color;
        transition: color .3s;
    }
    a.item:hover,
    a.item:focus {
        color: $anchor-color-hover;
    }
}
.item-group.callouts-4 {
    // @include callouts-3;
    @include callout-basic-item(
        $image-size: (
            small: $baseline * 8,
            medium: $baseline * 10
        ),
        $baseline: (
            small: $baseline,
        ),
        $compact-breakpoint: false,
        $constrain-image-width: false
    );
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                smallplus: 2,
                large: 4
            ),
            $baseline: (
                small: $baseline * 1.5,
                medium: $baseline * 2
            ),
            $shrink-wrap: true
        );
        justify-content: center;
    }
    .head .text-group {
        margin-top: $baseline;
    }
    a.item {
        color: $anchor-color;
        transition: color .3s;
    }
    a.item:hover,
    a.item:focus {
        color: $anchor-color-hover;
    }
}
.item-group.callouts-grid {
    @include callouts-grid(
        $per-row: (
            small: 2,
            medium: 4
        )
    );
    @include callout-grid-item;
    @include callout-grid-item-plus(
        $background-color: $white,
        $background-color-hover: $white
    );
    .items {
        justify-content: center;
    }
    .item {
        outline: 1px solid $gray;
        box-shadow: 0 2px 4px rgba(0,0,0,0.6);
        transition: box-shadow .3s ease,
                    outline .3s ease;
    }
    a.item:hover,
    a.item:focus {
        box-shadow: 0 4px 14px -2px rgba(0,0,0,0.6);
        outline: 1px solid $primary-color;
    }
}
.item-group.callouts-icons {
    @include callout-basic-item(
        $image-size: (
            small: $baseline * 4,
            smallplus: $baseline * 6,
            medium: $baseline * 10
        ),
        $baseline: (
            small: $baseline * 2,
        ),
        $compact-breakpoint: false,
        $constrain-image-width: false
    );
    .items {
        @include flex-columns(
            $per-row: (
                small: 2,
                medium: 4
            ),
            $baseline: (
                small: $baseline * 1.5,
                480px: $baseline * 2
            ),
            $shrink-wrap: true
        );
        justify-content: center;
    }
    .head .image img {
        object-fit: contain;
    }
    a.item {
        color: $anchor-color;
        transition: color .3s;
    }
    a.item:hover,
    a.item:focus {
        color: $anchor-color-hover;
    }
}
.item-group.callouts-alternating {
    @include callouts-alternating;
}

.display-point.bottom .callouts-sections {
    margin-left: $gutter * -1;
    margin-right: $gutter * -1;
    .text-group {
        padding-left: $gutter;
        padding-right: $gutter;
        max-width: $site-width;
        margin-left: auto;
        margin-right: auto;
    }
}
